
import { useEffect, useState } from 'react'
import { KTCard, KTCardBody, KTIcon, PaginationFilter } from '../../../../../_metronic/helpers'
import { StaffListHeader } from '../../staff/staff-list/components/header/StaffListHeader'
import { useListView, ListViewProvider } from '../core/ListViewProvider'
import { QueryRequestProvider } from '../core/QueryRequestProvider'
import { QueryResponseProvider } from '../core/QueryResponseProvider'

import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { PaymentMode } from '../core/_models'
import { getPaymentModeList } from '../core/_requests'
import { PaymentModeModal } from './PaymentModeModal'
import { Tag } from 'primereact/tag'
import { MustHavePermission, SWSection, SWResource, SWAction } from '../../../../core/shared/core/permissionUtils'


const paginationFilter: PaginationFilter = {
  pageNumber: 1,
  pageSize: 500,
}
const PaymentModeList = () => {
  const [loading, setLoading] = useState(false);
  const { itemIdForUpdate } = useListView()
  const [term, setTerm] = useState<PaymentMode[]>([])
  const [globalFilterValue, setGlobalFilterValue] = useState<string>('');

  useEffect(() => {
    getPaymentModeList(paginationFilter).then((data) => {
      let result = data.data as PaymentMode[]
      setTerm(result)
      setLoading(false)
    })
  }, [itemIdForUpdate])

  const filteredData = term?.filter((item: PaymentMode) =>
    Object.values(item).some((value: string | boolean) =>
      typeof value === 'string' && value.toLowerCase().includes(globalFilterValue.toLowerCase())
    )
  );
  const { setItemIdForUpdate } = useListView()
  const openAddAccountModal = () => {
    setItemIdForUpdate(null)
  }

  const openEditAccountModal = (id:any) => {
    setItemIdForUpdate(id)
  }


  const actionBodyTemplate = (rowData: any) => {
    return (
        <div className="actions">
           <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm '
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        onClick={()=>{openEditAccountModal(rowData.id)}}
      >
        <span className="ki-duotone ki-pencil" >
        <span className="path1"></span>
          <span className="path2"></span >
        </span>
       
      </a>
        </div>
    );
};
const getSeverity = (status: any) => {
  switch (status) {
      case 'active':
          return 'success';
      case 'inactive':
          return 'danger';
  }
};
const statusBodyTemplate = (rowData: PaymentMode) => {
       
  return <Tag value={rowData.isActive ? 'Active' : 'Inactive'} severity={getSeverity(rowData.isActive ? 'active' : 'inactive')} className={`badge fw-bolder ${rowData.isActive ? 'badge-light-success' : 'badge-light-danger'}`} />;
};

const renderHeader = () => (

  <><div className='w-100 d-flex align-items-center position-relative justify-content-between my-1'>
    <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-4' />
    <input
      type='text'
      data-kt-customer-table-filter='search'
      className='form-control form-control-solid w-250px ps-14'
      placeholder='Search Payment Mode'
      value={globalFilterValue} onChange={(e) => setGlobalFilterValue(e.target.value)} />
    <button type='button' className='btn btn-primary' onClick={openAddAccountModal}>
      <KTIcon iconName='plus' className='fs-2' />
      Add
    </button>
  </div>
  </>   
);

const header = renderHeader();
  return (
    <>
      <KTCard>
        <div className="card-header">
          <div className="card-title w-100">
              {header}
          </div>
        </div>
        <KTCardBody>
          <>
            <div className="d-flex justify-content-end mt-4">
              <div className="m-0">
              </div>
              {/* {MustHavePermission(SWSection.Store, SWResource.PaymentModes, SWAction.Create) ?
              <button type='button' className='btn btn-primary' onClick={openAddAccountModal}>
                <KTIcon iconName='plus' className='fs-2' />
                Add
              </button>
              :null} */}
            </div>
            <div>
              <DataTable
                paginator rows={10} rowsPerPageOptions={[20,30, 50]}
                className='table-responsive'
                tableClassName='table border table-striped table-row-bordered gy-5 gs-7'
                paginatorClassName='pagination'
                paginatorLeft
                value={filteredData}
                tableStyle={{ minWidth: '75rem' }}
                breakpoint='960px'
               loading={loading}
               lazy
               //header={header}

              >
                <Column
                
                  //className='text-gray-600 fw-bold'
                  headerClassName='fw-semibold fs-6 text-gray-800'
                  field='name'
                  header={'PaymentMode Name'}
                  sortable
                  style={{ minWidth: '6rem' }}
                />
               
                <Column
                  field="isActive"
                  headerClassName='fw-semibold fs-6 text-gray-800'
                  // header="Status"
                  header={'Status'}
                  filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '12rem' }}
                  body={statusBodyTemplate} />
                
               {MustHavePermission(SWSection.Store, SWResource.PaymentModes, SWAction.Update) ?
                  <Column
                    field=""
                    headerClassName='fw-semibold fs-6 text-gray-800'
                    // header="Actions"
                    header={'Actions'}
                    body={actionBodyTemplate} /> : null}
              </DataTable>
            </div>
          </>
        </KTCardBody>
      </KTCard>

      {itemIdForUpdate !== undefined && <PaymentModeModal />}
    </>
  )
}

const PaymentModeWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <PaymentModeList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { PaymentModeWrapper }
