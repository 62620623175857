import React, {ReactNode, useEffect, useState} from 'react'
import {KTCard, KTCardBody, KTSVG} from '../../../../../_metronic/helpers'
import {Field, FormikProvider, useFormik} from 'formik'
import * as Yup from 'yup'

import {
  addPaymentMade,
  getBankList,
  getBillDetails,
  getPaymentModeList,
  getVendorList,
} from '../_request'
import {useQuery} from 'react-query'
import {PaymentMode, Vendor} from '../_models'
import {Calendar} from 'primereact/calendar'
import CustomSelect from '../../../../core/shared/components/CustomSelect'
import {SelectOptionProps} from '../../../../core/models/SelectOptionProps'
import clsx from 'clsx'
import {useNavigate, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {Result} from '../../../../core/models/Result'
import {camelize} from '../../../../core/utils/StringHelpers'
import {toZonedTime} from 'date-fns-tz'
import {format} from 'date-fns'
import {formatDate} from '../../../../core/utils/helpers'

const initialValues = {
  billId: 0,
  amountPaid: undefined,
  vendorId: undefined,
  paymentDate: new Date(),
  paymentModeId: undefined,
  referenceNumber: '',
  isAdvance: true,
  notes: '',
  chequeBankName: '',
  chequeNumber: '',
  chequeDate: new Date(),
  bankId: undefined,
}

const addPaymentMadeSchema = Yup.object().shape({
  vendorId: Yup.number().required('Please Select Vendor'),
  paymentDate: Yup.date().required('Please Enter Payment Date'),
  referenceNumber: Yup.string().required('Please Enter Reference Number'),
  paymentModeId: Yup.number().required('Please Select Payment Mode'),

  // Conditional validation for cheque-specific fields when payment mode is cheque
  chequeBankName: Yup.string().when('paymentModeId', ([paymentModeId], schema) => {
    return paymentModeId === 2
      ? schema.required('Please Enter Cheque Bank Name')
      : schema.notRequired()
  }),
  chequeNumber: Yup.string().when('paymentModeId', ([paymentModeId], schema) => {
    return paymentModeId === 2
      ? schema
          .required('Please Enter Cheque Number')
          .min(6, 'Cheque Number must be 6 digits')
          .max(6, 'Cheque Number must be 6 digits')
      : schema.notRequired()
  }),
  chequeDate: Yup.date().when('paymentModeId', ([paymentModeId], schema) => {
    return paymentModeId === 2 ? schema.required('Please Enter Cheque Date') : schema.notRequired()
  }),

  // Conditional validation for bank when payment mode is not cash
  bankId: Yup.number().when('paymentModeId', ([paymentModeId], schema) => {
    return paymentModeId !== 1 ? schema.required('Please Select Bank') : schema.notRequired()
  }),

  amountPaid: Yup.number()
    .required('Please Enter Amount')
    .positive('Amount must be a positive number'),
  // .min(1, 'Amount must be greater than zero'),

  notes: Yup.string().optional(),
})

const PaymentMadeAdd = () => {
  const navigate = useNavigate()
  const {id} = useParams()
  const [vendors, setVenders] = useState<SelectOptionProps[]>([])
  const [banks, setBanks] = useState<SelectOptionProps[]>([])
  const {data: paymentModeList} = useQuery({
    queryKey: ['paymentModeList'],
    queryFn: () => getPaymentModeList(),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    cacheTime: 0,
  })

  const {data: vendorList, isLoading: isVendorListLoading} = useQuery({
    queryKey: ['vendorList'],
    queryFn: () => getVendorList({}),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    cacheTime: 0,
  })
  const {data: bankList, isLoading: isBankListLoading} = useQuery({
    queryKey: ['bankList'],
    queryFn: () => getBankList({}),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    cacheTime: 0,
  })
  const {data: billDetails, isLoading: isBillDetailsLoading} = useQuery({
    queryKey: ['billDetails'],
    queryFn: () => getBillDetails(Number(id)),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    cacheTime: 0,
    enabled: !!id,
  })

  useEffect(() => {
    if (id) {
      formik.setFieldValue('amountPaid', billDetails?.orderTotal)
      formik.setFieldValue('vendorId', billDetails?.vendorId)
    }
  }, [id, isBillDetailsLoading])

  useEffect(() => {
    if (vendorList?.data) {
      let vendorArray: any[] = []
      vendorList?.data.map((item: any) => {
        return vendorArray.push({value: item.id, label: item.printName})
      })
      setVenders(vendorArray)
    }
    if (bankList?.data) {
      let bankArray: any[] = []
      bankList?.data.map((item: any) => {
        return bankArray.push({value: item.id, label: item.name})
      })
      setBanks(bankArray)
    }
  }, [isVendorListLoading, isBankListLoading])

  const handleApiError = (
    result: Result,
    setFieldError: (field: string, message: string) => void
  ) => {
    if (result.statusCode === 400) {
      result.propertyResults.forEach((error) =>
        setFieldError(camelize(error.propertyName), error.errorMessage)
      )
    }
    toast.error('An error occurred while saving the purchase order.')
  }

  const formik = useFormik({
    initialValues,
    validationSchema: addPaymentMadeSchema,
    onSubmit: async (formValues, {setFieldError, setSubmitting}) => {
      setSubmitting(true)
      // Add your submission logic here

      if (formik.values.amountPaid && formik.values.amountPaid <= 0) {
        setFieldError('amountPaid', 'Amount Paid must be greater than 0')
        setSubmitting(false)
        return
      }
      const paymentDate = toZonedTime(formValues.paymentDate as Date, 'Asia/Kolkata')
      const chequeDate = toZonedTime(formValues.chequeDate as Date, 'Asia/Kolkata')
      const paymentDateString = format(paymentDate, 'yyyy-MM-dd')
      const chequeDateString = format(chequeDate, 'yyyy-MM-dd')

      const paymentMadePayload = {
        billId: id ? Number(id) : undefined,
        amountPaid: formValues.amountPaid,
        vendorId: formValues.vendorId,
        paymentDate: paymentDateString,
        paymentModeId: formValues.paymentModeId,
        referenceNumber: formValues.referenceNumber,
        isAdvance: id ? false : true,
        notes: formValues.notes,
        chequeBankName: formValues.paymentModeId === 2 ? formValues.chequeBankName : undefined,
        chequeNumber: formValues.paymentModeId === 2 ? formValues.chequeNumber : undefined,
        chequeDate: formValues.paymentModeId === 2 ? chequeDateString : undefined,
        bankId: formValues.paymentModeId !== 1 ? formValues.bankId : undefined,
      }

      const result = await addPaymentMade(paymentMadePayload)
      if (result.hasOwnProperty('succeeded') && result?.succeeded) {
        toast.success('Payment made created successfully!')
        navigate('/purchase/payment-made/list')
      } else {
        handleApiError(result, setFieldError)
      }
    },
  })

  return (
    <FormikProvider value={formik}>
      {id && (
        <div className='d-flex flex-column flex-xl-row gap-5 gap-lg-5 mb-10'>
          <div className='card w-50'>
            <div className='card-header'>
              <div className='card-title'>
                <h3>Bill Details</h3>
              </div>
            </div>
            <div className='card-body'>
              <div className='table-responsive'>
                <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
                  <tbody className='fw-semibold text-gray-600'>
                    <tr>
                      <td className='text-muted'>
                        <div className='d-flex align-items-center'>
                          <i className='ki-duotone ki-profile-circle fs-2 me-2'>
                            <span className='path1' />
                            <span className='path2' />
                            <span className='path3' />
                          </i>{' '}
                          Bill Number
                        </div>
                      </td>
                      <td className='fw-bold text-end'>
                        <div className='d-flex align-items-center justify-content-end'>
                          <label className='text-gray-600 text-hover-primary'>
                            {billDetails?.billNumber || 'Bill Number not available'}
                          </label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className='text-muted'>
                        <div className='d-flex align-items-center'>
                          <i className='ki-duotone ki-profile-circle fs-2 me-2'>
                            <span className='path1' />
                            <span className='path2' />
                            <span className='path3' />
                          </i>{' '}
                          Bill Date
                        </div>
                      </td>
                      <td className='fw-bold text-end'>
                        <div className='d-flex align-items-center justify-content-end'>
                          <label className='text-gray-600 text-hover-primary'>
                            {formatDate(billDetails?.billDate, 'dd-MM-yyyy') ||
                              'Bill Date not available'}
                          </label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className='text-muted'>
                        <div className='d-flex align-items-center'>
                          <i className='ki-duotone ki-profile-circle fs-2 me-2'>
                            <span className='path1' />
                            <span className='path2' />
                            <span className='path3' />
                          </i>{' '}
                          Payment Status{' '}
                        </div>
                      </td>
                      <td className='fw-bold text-end'>
                        <div className='d-flex align-items-center justify-content-end'>
                          <label className='text-gray-600 text-hover-primary'>
                            {billDetails?.paymentStatusName || 'Payment Status not available'}
                          </label>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      <KTCard>
        <div className='d-flex flex-wrap flex-stack gap-5 gap-lg-10'>
          <div className='card-header w-100'>
            <div className='card-title'>
              <h3>Add Payment Made</h3>
            </div>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className='card-body d-flex flex-column gap-5'>
            <div className='d-flex flex-row align-items-center'>
              <div className='col-xl-2 col-lg-3 col-md-4'>
                <label className='form-label required'>Payment Date</label>
              </div>
              <div className='col-xl-6 col-lg-9 col-md-8'>
                <Calendar
                  name='paymentDate'
                  className='custom-calendar p-inputtext'
                  dateFormat='dd-mm-yy'
                  value={
                    formik.values.paymentDate
                      ? new Date(formik.values.paymentDate)
                      : new Date(initialValues?.paymentDate || '')
                  }
                  onChange={(e) => formik.setFieldValue('paymentDate', e.value)}
                />
                {formik.touched.paymentDate && formik.errors.paymentDate && (
                  <div className='text-danger'>{formik.errors.paymentDate as ReactNode}</div>
                )}
              </div>
            </div>
            <div className='row align-items-center'>
              <div className='col-xl-2 col-lg-3 col-md-4'>
                <label className='form-label required'>Vendor</label>
              </div>
              <div className='col-xl-6 col-lg-9 col-md-8'>
                <Field
                  className='form-select-solid'
                  options={vendors || []}
                  component={CustomSelect}
                  {...formik.getFieldProps('vendorId')}
                  value={formik.values.vendorId}
                  name={'vendorId'}
                  disabled={!!id}
                  placeholder={'Select Vendor'}
                ></Field>
                {formik.touched.vendorId && formik.errors.vendorId && (
                  <div className='text-danger'>{formik.errors.vendorId}</div>
                )}
              </div>
            </div>
            <div className='row align-items-center'>
              <div className='col-xl-2 col-lg-3 col-md-4'>
                <label className='form-label required'>Reference Number</label>
              </div>
              <div className='col-xl-6 col-lg-9 col-md-8'>
                <Field
                  className='form-control'
                  type='text'
                  {...formik.getFieldProps('referenceNumber')}
                  value={formik.values.referenceNumber}
                  name={'referenceNumber'}
                  placeholder={'Enter Reference Number'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue('referenceNumber', e.target.value)
                  }}
                ></Field>
                {formik.touched.referenceNumber && formik.errors.referenceNumber && (
                  <div className='text-danger'>{formik.errors.referenceNumber}</div>
                )}
              </div>
            </div>

            <div className='d-flex flex-row align-items-center'>
              <div className='col-xl-2 col-lg-3 col-md-4'>
                <label className='form-label required'>Payment Mode</label>
              </div>
              <div className='d-flex flex-row gap-2'>
                {paymentModeList?.data?.map((option: PaymentMode) => (
                  <div key={option.id} className='form-check form-check-inline'>
                    <input
                      type='radio'
                      className='form-check-input'
                      name='paymentModeId'
                      id={`paymentMode-${option.id}`}
                      value={formik.values.paymentModeId}
                      checked={formik.values.paymentModeId === option.id}
                      onChange={() => formik.setFieldValue('paymentModeId', option.id)}
                    />
                    <label className='form-check-label' htmlFor={`paymentMode-${option.id}`}>
                      {option.name}
                    </label>
                  </div>
                ))}
                {formik.touched.paymentModeId && formik.errors.paymentModeId && (
                  <div className='text-danger'>{formik.errors.paymentModeId}</div>
                )}
              </div>
            </div>

            {formik.values.paymentModeId == 2 && (
              <>
                <div className='row align-items-center'>
                  <div className='col-xl-2 col-lg-3 col-md-4'>
                    <label className='form-label required'>Cheque Bank Name</label>
                  </div>
                  <div className='col-xl-6 col-lg-9 col-md-8'>
                    <Field
                      className='form-control'
                      type='text'
                      {...formik.getFieldProps('chequeBankName')}
                      value={formik.values.chequeBankName}
                      name={'chequeBankName'}
                      placeholder={'Enter Reference Number'}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        formik.setFieldValue('chequeBankName', e.target.value)
                      }}
                    ></Field>
                    {formik.touched.chequeBankName && formik.errors.chequeBankName && (
                      <div className='text-danger'>{formik.errors.chequeBankName}</div>
                    )}
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='row align-items-center'>
                      <div className='col-4'>
                        <label className='form-label required'>Cheque Number</label>
                      </div>
                      <div className='col-8'>
                        <Field
                          className='form-control'
                          type='text'
                          {...formik.getFieldProps('chequeNumber')}
                          value={formik.values.chequeNumber}
                          name={'chequeNumber'}
                          placeholder={'Enter Cheque Number'}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const numericValue = e.target.value.replace(/\D/g, '')
                            formik.setFieldValue('chequeNumber', numericValue)
                          }}
                        />
                        {formik.touched.chequeNumber && formik.errors.chequeNumber && (
                          <div className='text-danger'>{formik.errors.chequeNumber}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='row align-items-center'>
                      <div className='col-4'>
                        <label className='form-label required'>Cheque Date</label>
                      </div>
                      <div className='col-8'>
                        <Calendar
                          name='chequeDate'
                          className='custom-calendar p-inputtext w-100'
                          dateFormat='dd/mm/yy'
                          value={
                            formik.values.chequeDate
                              ? new Date(formik.values.chequeDate)
                              : new Date(initialValues?.chequeDate || '')
                          }
                          onChange={(e) => formik.setFieldValue('chequeDate', e.value)}
                          placeholder='Cheque Date'
                        />
                        {formik.touched.chequeDate && formik.errors.chequeDate && (
                          <div className='text-danger'>{formik.errors.chequeDate as ReactNode}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {formik.values.paymentModeId !== 1 && (
              <div className='row'>
                <div className='col-xl-2 col-lg-3 col-md-4'>
                  <label className='form-label required'>Paid Through</label>
                </div>
                <div className='col-xl-6 col-lg-9 col-md-8'>
                  <Field
                    className='form-select-solid'
                    options={banks || []}
                    component={CustomSelect}
                    {...formik.getFieldProps('bankId')}
                    value={formik.values.bankId}
                    name={'bankId'}
                    placeholder={'Select Bank'}
                  ></Field>
                  {formik.touched.bankId && formik.errors.bankId && (
                    <div className='text-danger'>{formik.errors.bankId}</div>
                  )}
                </div>
              </div>
            )}
            <div className='d-flex flex-row align-items-center'>
              <div className='col-xl-2 col-lg-3 col-md-4'>
                <label className='form-label required'>Amount Paid</label>
              </div>
              <div className='col-xl-6 col-lg-9 col-md-8'>
                <Field
                  className='form-control'
                  type='text'
                  {...formik.getFieldProps('amountPaid')}
                  value={formik.values.amountPaid}
                  name={'amountPaid'}
                  disabled={!!id}
                  placeholder={'Enter Amount Paid'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const numericValue = e.target.value.replace(/\D/g, '')
                    formik.setFieldValue('amountPaid', numericValue)
                  }}
                />
                {formik.touched.amountPaid && formik.errors.amountPaid && (
                  <div className='text-danger'>{formik.errors.amountPaid}</div>
                )}
              </div>
            </div>

            <div className='d-flex flex-row align-items-center'>
              <div className='col-xl-2 col-lg-3 col-md-4'>
                <label className='form-label'>Notes</label>
              </div>
              <div className='col-xl-6 col-lg-9 col-md-8'>
                <textarea
                  rows={3}
                  className='form-control'
                  placeholder='Notes'
                  {...formik.getFieldProps('notes')}
                  name={'notes'}
                ></textarea>
              </div>
            </div>

            {/* Add other form fields similarly */}
          </div>
          <div className='card-footer text-end'>
            <button
              type='reset'
              className='btn btn-light me-3'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_1'
              disabled={formik.isSubmitting}
            >
              Discard
            </button>
            <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
              <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h2>Confirmation</h2>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </div>
                  <div className='modal-body'>
                    <div className='w-100'></div>
                    <div className='fv-row text-center'>
                      <div className='fv-row text-center'>
                        <h5>Are you sure you want to discard ?</h5>
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer'>
                    <button
                      type='button'
                      className='btn btn-light'
                      data-bs-dismiss='modal'
                      onClick={() => navigate('/purchase/payment-made/list')}
                    >
                      Yes
                    </button>
                    <button type='button' className='btn btn-primary' data-bs-dismiss='modal'>
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-color-modal-action='submit'
              disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
            >
              <span className='indicator-label'>Submit</span>
              {formik.isSubmitting && (
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </KTCard>
    </FormikProvider>
  )
}

export default PaymentMadeAdd
