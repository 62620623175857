import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FileUploadRequest } from '../../../../core/models/FileUploadRequest'
import * as Yup from "yup";
import { Field, FormikProvider, useFormik } from 'formik';
import { KTSVG, PaginationFilter, isNotEmpty } from '../../../../../_metronic/helpers';
import { Result } from '../../../../core/models/Result';
import { toast } from 'react-toastify';
import { camelize } from '../../../../core/utils/StringHelpers';
import UploadImage from '../../../../core/shared/components/UploadImage';
import clsx from 'clsx';
import CustomSelect from '../../../../core/shared/components/CustomSelect';
import { SelectOptionProps } from '../../../../core/models/SelectOptionProps';
import { Page, SEO, initialSEO } from '../core/_models';
import { createSEO, getPageList, updateSEO } from '../core/_requests';

const paginationFilter: PaginationFilter = {
    pageNumber: 1,
    pageSize: 500,


};

type Props = {
    isSEOLoading: boolean;
    data: Result | SEO;
};


const SEOEditPage: FC<Props> = ({ data, isSEOLoading }) => {
    const [pageList, setPageList] = useState<SelectOptionProps[]>([])
    const navigate = useNavigate()

    const seoSchema = Yup.object().shape({

        metaTitle: Yup.string()
            .required("Please enter Meta Title"),
        metaKeywords: Yup.string()

            .required("Please enter Meta keywords"),
        metaDescription: Yup.string()

            .required("Please enter Meta Description"),
        googleTagManager: Yup.string()

            .required("Please enter GoogleTag Manager"),
        pageId: Yup.number().required('Please select the page'),

    })

    const seoData = data as SEO

    const initialValues = {
        id: seoData.id,
        metaTitle: seoData.metaTitle,
        metaKeywords: seoData.metaKeywords,
        metaDescription: seoData.metaDescription,
        googleTagManager: seoData.googleTagManager,
        pageId: seoData.pageId
    }

    const formik = useFormik({
        enableReinitialize: false,
        initialValues: initialValues,
        validationSchema: seoSchema,

        onSubmit: async (formValues, { setSubmitting, setFieldError }) => {

            setSubmitting(true)
            let seoData: SEO = {
                id: formValues.id,
                pageId: formValues.pageId,
                metaTitle: formValues.metaTitle,
                metaKeywords: formValues.metaKeywords,
                metaDescription: formValues.metaDescription,
                googleTagManager: formValues.googleTagManager
            }

            try {
                let result: Result;
                if (isNotEmpty(formValues.id)) {
                    result = await updateSEO(seoData)
                    if (result.hasOwnProperty('succeeded') && result?.succeeded) {
                        setSubmitting(true)
                        toast.success("SEO details updated successfully.")
                        navigate('/cms/seo')

                    }
                    else {
                        if (result.statusCode === 400) {
                            result.propertyResults.map((error) =>
                                setFieldError(camelize(error.propertyName), error.errorMessage),
                                toast.error(result.exception)

                            )
                        }
                    }
                }
            } catch (ex) {
                console.error(ex)
                toast.error("Error caught during updated seo.")
            }
        },
    })

    useEffect(() => {
        let result: any

        getPageList(paginationFilter).then((v) => {
            result = v.data as Page[]
            let pageArray: any[] = []
            result.map((item: any) => {
                return pageArray.push({ value: item.id, label: item.name })
            })
            setPageList(pageArray)
        })


    }, [])

    return (
        <FormikProvider value={formik}>
            <form
                id='banner_form'
                className='form'
                onSubmit={formik.handleSubmit}
                noValidate

            >
                <div className="card mb-5 mb-xl-5">
                    <div className="card-header">
                        <div className="card-title">
                            <h3 className="fw-bold">SEO</h3>
                        </div>
                    </div>

                    <div className="card-body">
                        <div className="row mb-xl-5">

                            <label className="col-lg-2 col-md-3 col-form-label fw-semibold fs-6 required">
                                Meta Title
                            </label>

                            <div className="col-lg-8">
                                <Field
                                    type='text'
                                    className={clsx(
                                        'form-control form-control-solid mb-lg-0',
                                        { 'is-invalid': formik.touched.metaTitle && formik.errors.metaTitle },
                                        {
                                            'is-valid': formik.touched.metaTitle && !formik.errors.metaTitle,
                                        }
                                    )}
                                    placeholder='Meta Title'
                                    {...formik.getFieldProps('metaTitle')}
                                    name='metaTitle'
                                />
                                {formik.touched.metaTitle && formik.errors.metaTitle && (
                                    <div className='fv-help-block mt-1'>
                                        <span role='alert ' className="text-danger">{formik.errors.metaTitle}</span>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="row mb-xl-5">

                            <label className="col-lg-2 col-md-3 col-form-label  fw-semibold fs-6 required">
                                Meta Keywords
                            </label>

                            <div className="col-lg-8">


                                <Field
                                    type='text'
                                    className={clsx(
                                        'form-control form-control-solid mb-lg-0',
                                        { 'is-invalid': formik.touched.metaKeywords && formik.errors.metaKeywords },
                                        {
                                            'is-valid': formik.touched.metaKeywords && !formik.errors.metaKeywords,
                                        }
                                    )}
                                    placeholder='Meta Keywords'
                                    {...formik.getFieldProps('metaKeywords')}
                                    name='metaKeywords'
                                />
                                {formik.touched.metaKeywords && formik.errors.metaKeywords && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.metaKeywords}</span>
                                        </div>
                                    </div>
                                )}
                                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />


                            </div>

                        </div>

                        <div className="row mb-xl-5">

                            <label className="col-lg-2 col-md-3 col-form-label  fw-semibold fs-6 required">
                                GoogleTag Manager
                            </label>

                            <div className="col-lg-8">


                                <Field
                                    type='text'
                                    className={clsx(
                                        'form-control form-control-solid mb-lg-0',
                                        { 'is-invalid': formik.touched.googleTagManager && formik.errors.googleTagManager },
                                        {
                                            'is-valid': formik.touched.googleTagManager && !formik.errors.googleTagManager,
                                        }
                                    )}
                                    placeholder='Google Tag Manager'
                                    {...formik.getFieldProps('googleTagManager')}
                                    name='googleTagManager'
                                />
                                {formik.touched.googleTagManager && formik.errors.googleTagManager && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.googleTagManager}</span>
                                        </div>
                                    </div>
                                )}
                                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />

                            </div>

                        </div>

                        <div className="row mb-xl-5">

                            <label className="col-lg-2 col-md-3 col-form-label required fw-semibold fs-6">
                                Page
                            </label>

                            <div className="col-lg-8">

                                <Field
                                    className='form-select-solid'
                                    component={CustomSelect}
                                    id="pageId"
                                    {...formik.getFieldProps('pageId')}
                                    name="pageId"
                                    options={pageList}


                                >
                                </Field>
                                {formik.touched.pageId && formik.errors.pageId && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.pageId}</span>
                                        </div>
                                    </div>
                                )}
                                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />


                            </div>

                        </div>

                        <div className="row">
                            <label className="col-lg-2 col-md-3 col-form-label fw-semibold fs-6 required">
                                Meta Description
                            </label>
                            <div className="col-lg-8">
                                <textarea
                                    className='form-control form-control-solid mb-lg-0'
                                    rows={4}
                                    id="metaDescription"
                                    {...formik.getFieldProps('metaDescription')}
                                    name="metaDescription"
                                />

                                {formik.touched.metaDescription && formik.errors.metaDescription && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.metaDescription}</span>
                                        </div>
                                    </div>
                                )}
                                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />
                            </div>
                        </div>
                    </div>
                    <div className="card-footer text-end">
                        <button
                            type='reset'
                            className='btn btn-light me-3'
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_1"
                            disabled={formik.isSubmitting}
                        >
                            Discard
                        </button>
                        <div className="modal fade" tabIndex={-1} id="kt_modal_1">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h2>Confirmation</h2>
                                        <div
                                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <KTSVG
                                                path="/media/icons/duotune/arrows/arr061.svg"
                                                className="svg-icon svg-icon-2x"
                                            />
                                        </div>
                                    </div>
                                    <div className="modal-body">
                                        <div className="w-100">

                                            {/* <div className="fv-row mb-10 d-flex justify-content-center exclamation fs-14">
												<i className="las la-exclamation-circle"></i>
											</div> */}

                                            <div className="fv-row text-center">
                                                <h5>Are you sure you want to discard ?</h5>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-light"
                                            data-bs-dismiss="modal"
                                            onClick={() => navigate('/cms/seo/list')}
                                        >
                                            Yes
                                        </button>
                                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal">
                                            No
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button
                            type='submit'
                            className='btn btn-primary'
                            data-kt-color-modal-action='submit'
                            disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                        >
                            <span className='indicator-label'>Submit</span>
                            {(formik.isSubmitting) && (
                                <span className='indicator-progress'>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>

                </div>
            </form>
        </FormikProvider>
    )

}

export default SEOEditPage