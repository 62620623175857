import React, { useState } from "react";
import {  ProductBarcodeData } from "../product/product-list/core/_models";
import GenerateBarcode from "./Barcode";

type Props = {
    products: ProductBarcodeData[],
    pricesep: number,
}


class PrintButton extends React.PureComponent<Props>{
    render() {

        let products = this.props.products;
        let pricesep = this.props.pricesep;
        
        
        function printFunction(product, index) {
            let indents: React.ReactNode[] = [];
            

            for (let i = 0; i < product.quantity; i++) {

                indents.push(
                    <><div key={i} className='barcode-main__barcode-item barcode-main__barcode-style'
                        style={{ width: '38mm', height: '15mm' }}
                    >
                        <div className='fw-bolder-barcode' style={{ float: 'left', fontSize: '9px', fontWeight: '600' }}>{product.categoryName}</div>
                        <div className='text-capitalize fw-bolder-barcode' style={{ float: 'right', fontSize: '9px', fontWeight: '600' }}>{product.designNumber}</div>
                        <GenerateBarcode value={product.barcode as string} displayValue={false} format="CODE128" width={1.2} height={25} textAlign={"center"} margin={0} />
                        <div style={{ float: 'left', fontSize: '9px', fontWeight: '600' }} className="fw-bolder-barcode " >{product.colorName}</div>
                        <div style={{ float: 'right', fontSize: '9px', fontWeight: '600' }} className="fw-bolder-barcode ">{product.spWholeSeller}{pricesep}{product.spSemiWholeSeller}</div>
                    </div></>);
            }
            return indents;
        }

        return (
            <div>
                {products && products.map((item, index) => {
                    return printFunction(item, index)
                })}
            </div>
        );

    }
}

export default PrintButton