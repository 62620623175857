import React, {useState} from 'react'
import {useQuery} from 'react-query'
import {Link, useParams} from 'react-router-dom'
import {downloadPaymentMade, getPaymentMadeDetails} from '../_request'
import {KTCard, KTCardBody, KTIcon} from '../../../../../_metronic/helpers'
import {formatCurrency, formatDate} from '../../../../core/utils/helpers'

const PaymentMadeDetails = () => {
  const {id} = useParams()
  const {isLoading, data: result} = useQuery(
    ['getPaymentMadeData'],
    async () => {
      return await getPaymentMadeDetails(Number(id))
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err)
      },
    }
  )
  const [activeTab, setActiveTab] = useState<string>('payment_made_summary')

  console.log(result)

  const handleDownloadReceipt = () => {
    const filePath = result?.pdfPath
    const link = document.createElement('a')
    link.href = filePath
    link.download = 'Receipt.pdf'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <div id='kt_app_content' className='app-content  flex-column-fluid '>
      <div id='kt_app_content_container' className='app-container  container-xxl '>
        <div className='d-flex flex-column gap-5 gap-lg-5'>
          <KTCard>
            <KTCardBody>
              <div className='d-flex flex-wrap flex-stack gap-5 gap-lg-10'>
                <ul
                  className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-lg-n2 me-auto'
                  role='tablist'
                >
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link'
                      data-bs-toggle='tab'
                      data-bs-target='#kt_tab_pane_payment_made_summary'
                      type='button'
                      role='tab'
                      aria-controls='kt_tab_pane_payment_made_summary'
                      aria-selected='true'
                      onClick={() => setActiveTab('payment_made_summary')}
                    >
                      Payment Made Summary
                    </button>
                  </li>
                </ul>
                <div className='d-flex gap-10 align-items-center '>
                  <Link
                    to={`/purchase/payment-made/list`}
                    className='btn btn-light btn-active-secondary btn-sm ms-auto me-lg-n7'
                  >
                    <i className='ki-duotone ki-left fs-2' /> Back
                  </Link>
                  <Link
                    to={`/purchase/payment-made/add/${id}`}
                    className='btn btn-primary btn-sm ms-auto me-lg-n7'
                  >
                    <KTIcon iconName='plus' className='fs-2' /> Add Payment Made
                  </Link>
                  <div
                    className='btn btn-success btn-sm ms-auto me-lg-n7 ms-2'
                    onClick={() => downloadPaymentMade(Number(id))}
                  >
                    <i className='ki-duotone ki-edit fs-2' /> Download Receipt
                  </div>
                </div>
              </div>
            </KTCardBody>
          </KTCard>

          <div className='d-flex flex-column flex-xl-row gap-5 gap-lg-5'>
            <div className='card flex-row-fluid'>
              <div className='card-header'>
                <div className='card-title'>
                  <h3>Payment Made Summary (#{result?.paymentNumber})</h3>
                </div>
              </div>

              <div className='card-body'>
                <div className='table-responsive'>
                  <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
                    <tbody className='fw-semibold text-gray-600'>
                      <tr>
                        <td className='text-muted'>Reference Number</td>
                        <td className='fw-bold text-end'>{result?.referenceNumber}</td>
                      </tr>
                      <tr>
                        <td className='text-muted'>Payment Date</td>
                        <td className='fw-bold text-end'>
                          {result?.paymentDate
                            ? formatDate(result.paymentDate, 'dd MMM yyyy')
                            : 'N/A'}
                        </td>
                      </tr>
                      <tr>
                        <td className='text-muted'>Amount Paid</td>
                        <td className='fw-bold text-end'>{formatCurrency(result?.amountPaid)}</td>
                      </tr>
                      <tr>
                        <td className='text-muted'>Payment Mode </td>
                        <td className='fw-bold text-end'>{result?.paymentModeName}</td>
                      </tr>
                      {/* <tr>
                        <td className='text-muted'>Payment Status</td>
                        <td className='fw-bold text-end'>{result?.paymentModeName}</td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className='card flex-row-fluid'>
              <div className='card-header'>
                <div className='card-title'>
                  <h3>Vendor Details</h3>
                </div>
              </div>

              <div className='card-body'>
                <div className='table-responsive'>
                  <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
                    <tbody className='fw-semibold text-gray-600'>
                      <tr>
                        <td className='text-muted'>Vendor Name</td>
                        <td className='fw-bold text-end'>{result?.vendorDetails?.vendorName}</td>
                      </tr>
                      <tr>
                        <td className='text-muted'>Vendor Phone</td>
                        <td className='fw-bold text-end'>{result?.vendorDetails?.mobileNumber}</td>
                      </tr>
                      <tr>
                        <td className='text-muted'>Vendor Email</td>
                        <td className='fw-bold text-end'>{result?.vendorDetails?.emailAddress}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {result?.paymentModeId !== 1 && (
              <div className='card flex-row-fluid'>
                <div className='card-header'>
                  <div className='card-title'>
                    <h3>Bank Details</h3>
                  </div>
                </div>

                <div className='card-body'>
                  <div className='table-responsive'>
                    <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
                      <tbody className='fw-semibold text-gray-600'>
                        <tr>
                          <td className='text-muted'>Bank Name</td>
                          <td className='fw-bold text-end'>{result?.bankName || 'Bank Name'}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='tab-content'>
            <div
              className={`tab-pane fade ${activeTab == 'payment_made_summary' ? 'active show' : ''} `}
              id='kt_ecommerce_purchase_bill_summary'
              role='tab-panel'
            >
              <div className='d-flex flex-column gap-5 gap-lg-5'>
                <div className='card flex-row-fluid overflow-hidden'>
                  <div className='card-body'>
                    <div className='table-responsive'>
                      <table className='table align-middle table-row-dashed fs-6 gy-3 mb-0'>
                        <thead className='fw-semibold text-gray-600'>
                          <tr className='text-start text-gray-600 '>
                            <th className='min-w-70px'>Bill Number</th>
                            <th className='min-w-70px'>Bill Date</th>
                            <th className='min-w-70px'>Payment Date</th>
                            <th className='min-w-70px'>Amount Paid</th>
                          </tr>
                        </thead>
                        <tbody className='fw-semibold text-gray-600'>
                          {result?.billPayments?.map((item: any) => (
                            <tr key={item.id}>
                              <td>{item.billNumber}</td>
                              <td>{formatDate(item.billDate, 'dd MMM yyyy')}</td>
                              <td>{formatDate(item.paymentDate, 'dd MMM yyyy')}</td>
                              <td>{formatCurrency(item.amountPaid)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentMadeDetails
