import axios, { AxiosResponse } from "axios";
import { PurchaseOrder, PurchaseOrderItem, PurchaseOrderPayment, PurchaseOrderQueryResponse, PurchaseReceive, PurchaseReceiveList, PurchaseUpdateStatus } from "./_models";
import {
  ID,
  PaginationFilter,
} from "../../../../../../_metronic/helpers/crud-helper/models";
import { Result } from "../../../../../core/models/Result";
import { Vendor } from "../../../vendor/vendor-list/core/_models";
import { SaleOrderDto } from "../../../../sales/salesOrder/salesOrder-list/core/_models";
const API_URL = process.env.REACT_APP_STORE_API_URL;
const PurchaseOrder_BYID_URL = `${API_URL}/purchaseorders`;
const PurchaseOrder_ADD_URL = `${API_URL}/purchaseorders`;
const PurchaseOrder_LIST_URL = `${API_URL}/purchaseorders/search`;
const Purchase_Order_Payment_URL = `${API_URL}/purchaseorders/purchaseorderpayment`
const PurchaseOrderItem_URL = `${API_URL}/purchaseorders/purchaseorderitem`
const PurchaseRecieve_URL = `${API_URL}/purchasereceives`
const PurchaseStatus_URL = `${API_URL}/purchaseorders/update-status`
const Vendor_BYID_URL = `${API_URL}/vendors`
const SalesOrder_BYID_URL = `${API_URL}/saleorders/non-po-saleorders`
const Pending_PurchaseOrder_List_Url =`${API_URL}/purchaseorders/pending-purchase-receive`
const PurchaseReceive_LIST_URL = `${API_URL}/purchasereceives/list`
const PurchaseOrder_Update_Status_URL = `${API_URL}/purchaseorders/update-status`

const getPendingPurchaseOrderList = async() => {
  return await axios.get(`${Pending_PurchaseOrder_List_Url}` , {
    headers:{
     'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  }).then((d) => d.data).catch((err) => { return err})
}

const updatePurchaseOrderStatus = async (id: ID, note: string): Promise<Result> => {
  return await axios.post(`${PurchaseOrder_Update_Status_URL}`, {
    purchaseOrderId: id,
    purchaseOrderStatusId: 3,
    notes: note
  }).then((d) => d.data).catch((err) => { return err})
} 

const getPurchaseReceiveList = async(id: ID): Promise<Result | PurchaseReceiveList> => {
  return await axios
  .get(`${PurchaseReceive_LIST_URL}/${id}`)
  .then((d: AxiosResponse<PurchaseReceiveList>) => d.data)
  .catch((err: Result) => { return err})
}

const getPurchaseOrderList = async(
  filter: PaginationFilter
): Promise<PurchaseOrderQueryResponse> => {

  return axios
    .post(`${PurchaseOrder_LIST_URL}`, filter)
    .then((d: AxiosResponse<PurchaseOrderQueryResponse>) => {
      return d.data;
    })
    .catch((err: Result) => {
      return err;
    });
};
const getPurchaseOrderById = async (
  id: ID
): Promise<Result | PurchaseOrder> => {
  return await axios
    .get(`${PurchaseOrder_BYID_URL}/${id}`)
    .then((response: AxiosResponse<PurchaseOrder>) => response.data)
    .then((response: PurchaseOrder) => response)
    .catch((err: Result) => {
      return err;
    });
};

const getVendorById = async (id: ID): Promise<Result | Vendor> => {
  return await axios
    .get(`${Vendor_BYID_URL}/${id}`)
    .then((response: AxiosResponse<Vendor>) => response.data)
    .then((response: Vendor) => response)
    .catch((err: Result) => {
      return err;
    });
};


const createPurchaseOrder = async (
  purchaseOrder: PurchaseOrder
): Promise<Result> => {
  return await axios
    .post(PurchaseOrder_ADD_URL, {
      vendorId: purchaseOrder.vendorId,
      saleOrderId: purchaseOrder.saleOrderId,
      orderDate: purchaseOrder.orderDate,
      notes: purchaseOrder.notes,
      itemList: purchaseOrder.itemList,
      deliveryAddressId: purchaseOrder.deliveryAddressId,
      expectedDeliveryDate: purchaseOrder.expectedDeliveryDate,
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err;
    });
};

const updatePurchaseOrder = (purchaseOrder: PurchaseOrder): Promise<Result> => {
  return axios
    .put(`${PurchaseOrder_BYID_URL}/${purchaseOrder.id}`, {
      id: purchaseOrder.id,
      vendorId: purchaseOrder.vendorId,
      orderDate: purchaseOrder.orderDate,
      purchaseOrderStatusId: purchaseOrder.purchaseOrderStatusId,
      notes: purchaseOrder.notes,
      itemList: purchaseOrder.itemList,
      billAddressLine1: purchaseOrder.billAddressLine1,
      billAddressLine2: purchaseOrder.billAddressLine2,
      billStateId: purchaseOrder.billStateId,
      billCountryId: purchaseOrder.billCountryId,
      shipAddressLine1: purchaseOrder.shipAddressLine1,
      shipAddressLine2: purchaseOrder.shipAddressLine2,
      shipStateId: purchaseOrder.shipStateId,
      shipCountryId: purchaseOrder.shipCountryId,
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err;
    });
};
const createPOPayment = async (payment: PurchaseOrderPayment): Promise<Result> => {
  return await axios
    .post(Purchase_Order_Payment_URL, {
      purchaseOrderId: payment.purchaseOrderId,
      amountPaid: payment.amountPaid,
      paymentDate: payment.paymentDate,
      paymentModeId: payment.paymentModeId,
      referenceNumber: payment.referenceNumber,
      notes: payment.notes,
      vendorId:payment.vendorId,
      isAdvance:payment.isAdvance,
      accountId:payment.accountId
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
       
      return err
    })
}

const getPOPaymentById = async (id: ID): Promise<Result | PurchaseOrderPayment> => {
  return await axios
    .get(`${Purchase_Order_Payment_URL}/${id}`)
    .then((response: AxiosResponse<PurchaseOrderPayment>) => response.data)
    .then((response: PurchaseOrderPayment) => response)
    .catch((err: Result) => {
      return err
    })
}


const getpurchaseOrderItem = async (id: ID): Promise<Result | PurchaseOrderItem[]> => {
  return await axios
    .get(`${PurchaseOrderItem_URL}/${id}`)
    .then((response: AxiosResponse<PurchaseOrderItem[]>) => response.data)
    .then((response: PurchaseOrderItem[]) => response)
    .catch((err: Result) => {
      return err
    })
}

const createPurchaseOrderRecieve = async (
  poRecieve: PurchaseReceive
): Promise<Result> => {
  return await axios
    .post(PurchaseRecieve_URL, poRecieve)
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err;
    });
};


const updatePurchaseStatus = async (
  postatus: PurchaseUpdateStatus
): Promise<Result> => {
  return await axios
    .post(PurchaseStatus_URL, {
      purchaseOrderId: postatus.purchaseOrderId,
      purchaseOrderStatusId: postatus.purchaseOrderStatusId,
      notes: postatus.notes,
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err;
    });
};

const getNonPOsalesOrder = async (saleOrderFilter?: any): Promise<Result | SaleOrderDto> => {
  return await axios
    .post(`${SalesOrder_BYID_URL}`,{
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      },
      body: saleOrderFilter || ''
    }

    )
    .then((response: AxiosResponse<SaleOrderDto>) => response.data)
    .then((response: SaleOrderDto) => response)
    .catch((err: Result) => {
      return err;
    });
};

const postSendToVendor = async (id: ID): Promise<Result> => {
  return await axios
    .post(`${API_URL}/purchaseorders/${id}/sent-to-vendor`,{
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      },
      body: ''
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err;
    });
}


export {
  getPurchaseOrderById,
  getPurchaseOrderList,
  createPurchaseOrder,
  getPendingPurchaseOrderList,
  getVendorById,
  getPurchaseReceiveList,
  getNonPOsalesOrder,
  updatePurchaseOrder,
  createPOPayment,
  postSendToVendor,
  getPOPaymentById,
  getpurchaseOrderItem,
  createPurchaseOrderRecieve,
  updatePurchaseStatus,
  updatePurchaseOrderStatus
};
