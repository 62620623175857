import React, { FC, useEffect, useState } from 'react'
import Select from 'react-select'
import { Filter, initialQueryState, KTIcon, PaginationFilter, } from '../../../../../_metronic/helpers';
import { useQueryRequest } from '../core/QueryRequestProvider';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { useListView } from '../core/ListViewProvider';
import { getExpenseCategoryList } from '../../expenseCategory/core/_requests';
import { ExpenseCategory } from '../../expenseCategory/core/_models';
import { SelectOptionProps } from '../../../../core/models/SelectOptionProps';
import { format } from 'date-fns'
import { toZonedTime } from "date-fns-tz";

const paginationFilter: PaginationFilter = {
    pageNumber: 1,
    pageSize: 500,

};
interface Props {
    onSearch: (searchParams: { keyword: string | undefined, advancedFilter: Filter | Filter[] | undefined }) => void;
}

const ExpenseAdvancedSearch: FC<Props> = ({ onSearch }) => {
    const [advancedSearchVisible, setAdvancedSearchVisible] = useState(false);

    const { state, updateState } = useQueryRequest()
    const [filterStatus, updateFilterStatus] = useState<boolean | undefined>()
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined)
    const [filterStaffName, updateFilterStaffName] = useState<string>('')
    const [filterExpenseCategory, updateFilterExpenseCategory] = useState<number[] | undefined>(undefined)
    const [expenseCategoryList, setExpenseCategoryList] = useState<SelectOptionProps[]>([]);

    const [filterExpenseDateFrom, setFilterExpenseDateFrom] = useState<string | undefined | Date>(undefined);
    const [filterExpenseDateTo, setFilterExpenseDateTo] = useState<string | undefined | Date>(undefined);


    useEffect(() => {
        let result: any[] = []
        getExpenseCategoryList(paginationFilter).then((v) => {
            result = v.data as ExpenseCategory[]
            const expenseArray: any[] = []
            result.map((item: any) => {
                return expenseArray.push({ value: item.id, label: item.name })
            })
            setExpenseCategoryList(expenseArray)
        })
    }, [])

    const updateSearchFilters = () => {
        let filters: Filter[] = []
        if (filterStatus != undefined) {
            filters.push({
                field: 'orderStatusId',
                operator: 'eq',
                value: filterStatus
            })
        }

        if (filterStaffName != undefined && filterStaffName != '') {
            filters.push({
                field: 'staff.firstname',
                operator: 'contains',
                value: filterStaffName
            })
        }

        if (filterExpenseDateFrom || filterExpenseDateTo) {
            let orderDateFilters: Filter[] = [];
            if (filterExpenseDateFrom !== undefined) {
                const fromDate = toZonedTime(new Date(filterExpenseDateFrom as Date), 'Asia/Kolkata');
                orderDateFilters.push({
                    field: 'expenseDate',
                    operator: 'gte',
                    value: format(fromDate, 'yyyy-MM-dd 00:00:00'),
                });
            }

            if (filterExpenseDateTo !== undefined) {
                const toDate = toZonedTime(new Date(filterExpenseDateTo as Date), 'Asia/Kolkata');
                orderDateFilters.push({
                    field: 'expenseDate',
                    operator: 'lte',
                    value: format(toDate, 'yyyy-MM-dd 23:59:59'),
                });
            }
            filters.push({
                filters: orderDateFilters,
                logic: 'and',
            });
        }


        if (filterExpenseCategory != undefined) {
            let expenseFilter: Filter[] = []

            filterExpenseCategory.map((id) => {
                expenseFilter.push({
                    field: 'expenseCategoryId',
                    operator: 'eq',
                    value: id
                })
            })

            if (expenseFilter.length > 1) {
                filters.push({
                    filters: expenseFilter,
                    logic: 'or'
                })
            }
            else if (expenseFilter.length == 1) {
                filters.push(expenseFilter[0])
            }
        }

        if (filters.length > 1) {
            updateState({
                keyword: searchTerm,
                advancedFilter: {
                    filters: filters,
                    logic: 'and'
                }, ...initialQueryState
            })
        }

        else if (filters.length === 1) {
            updateState({
                keyword: searchTerm,
                advancedFilter: filters[0],
                ...initialQueryState
            })
        }
        else {
            updateState({
                keyword: searchTerm,
                advancedFilter: undefined,
                ...initialQueryState
            })
        }

        onSearch({
            keyword: searchTerm,
            advancedFilter: filters.length > 1 ? { filters, logic: 'and' } : filters.length === 1 ? filters[0] : undefined,
        });
    }


    const handleDateChange = (selectedDates: Date[], dateName: string) => {
        if (dateName === 'from') {
            if (selectedDates[0] === undefined) {
                setFilterExpenseDateFrom('')
            }
            else {
                setFilterExpenseDateFrom(selectedDates[0]?.toDateString());
            }

        } else if (dateName === 'to') {
            if (selectedDates[0] === undefined) {
                setFilterExpenseDateTo('')
            }
            else {
                setFilterExpenseDateTo(selectedDates[0]?.toDateString());
            }
        }

    };
    const { setItemIdForUpdate } = useListView()
    const openAddAccountModal = () => {
        setItemIdForUpdate(null)
    }

    return (
        <>
            <div className="card mb-5">
                <div className="card-header justify-content-between align-items-center">
                    <div className="card-title">
                        <h3>Expense Filter</h3>
                    </div>
                    <button className="btn"
                        onClick={() => setAdvancedSearchVisible(!advancedSearchVisible)}>
                        {
                            advancedSearchVisible ?
                                <img src='/media/icons/up-angle.svg' alt='Filter' height={25} width={25} />
                                :
                                <img src='/media/icons/down-angle.svg' alt='Filter' height={25} width={25} />
                        }
                    </button>
                </div>
                {advancedSearchVisible && (
                    <>
                        <div className="card-body">
                            {/* <div className="row mb-7">
                        <div className="col-lg-6">
                            <div className="input-group">
                                <i className="ki-duotone ki-magnifier fs-3 text-gray-500 position-absolute top-50 translate-middle ms-5 me-5">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>
                                <input
                                    type="text"
                                    className="form-control form-control-solid ps-10"
                                    id="txtsearchText"
                                    placeholder="Search Expense"
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <button className="btn btn-primary" id="btnSearch" onClick={updateSearchFilters}>
                                    Search
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <button
                                className="btn btn-link"
                                onClick={() => setAdvancedSearchVisible(!advancedSearchVisible)}
                            >
                                Advanced Search
                            </button>
                        </div>
                        <div className='col-lg-3 '>
                            <button type='button' className='btn btn-primary' onClick={openAddAccountModal}>
                                <KTIcon iconName='plus' className='fs-2' />
                                Add
                            </button>
                        </div>
                    </div> */}



                            <div className="collapse" id="kt_advanced_search_form">
                                <div className="separator separator-dashed mt-9 mb-6"></div>
                            </div>


                            <div className="row g-5 mb-5">

                                <div className="col-lg-4">
                                    <label className="fs-6 form-label fw-bold text-dark" >
                                        Staff Name
                                    </label>

                                    <input type="text" className="form-control" placeholder="Enter Staff Name" autoComplete='off' name="staff.firstName"
                                        value={filterStaffName} onChange={(e) => updateFilterStaffName(e.target.value)} />
                                </div>
                                <div className="col-lg-4">
                                    <label className="fs-6 form-label fw-bold text-dark" >
                                        Expense Category
                                    </label>
                                    <Select
                                        className='form-select-solid ms-1'
                                        name="expenseCategoryId"
                                        options={expenseCategoryList}
                                        placeholder="Select category"
                                        onChange={(e) => updateFilterExpenseCategory((e as SelectOptionProps[]).map((item: SelectOptionProps) => item.value))}
                                        isClearable={true}
                                        isMulti={true}
                                    ></Select>
                                </div>
                                <div className="col-lg-4">
                                    <label className="fs-6 form-label fw-bold text-dark">
                                        Expense Date
                                    </label>
                                    <div className="row g-3">
                                        <div className="col">
                                            <Flatpickr
                                                options={{ dateFormat: 'Y-m-d', altFormat: 'd-m-Y' }}
                                                onChange={(selectedDates) => handleDateChange(selectedDates, 'from')}
                                                className="form-control form-control-solid"
                                                placeholder="From"
                                                name="expenseDate"
                                            />
                                        </div>
                                        <div className="col">
                                            <Flatpickr
                                                options={{ dateFormat: 'Y-m-d', altFormat: 'd-m-Y' }}
                                                onChange={(selectedDates) => handleDateChange(selectedDates, 'to')}
                                                className="form-control form-control-solid"
                                                placeholder="To"
                                                name="expenseDate"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="col-lg-4">
                                    <label className="fs-6 form-label fw-bold text-dark">
                                        Status
                                    </label>
                                    <Select
                                        className='form-select-solid fw-bolder'
                                        name="statusId"
                                        options={invoiceStatusOptions}
                                        placeholder="Select Status"
                                        onChange={(e) => {
                                            if (e != null) {
                                                updateFilterStatus(e?.value === 1)
                                            }
                                            else {
                                                updateFilterStatus(undefined)
                                            }
                                        }}
                                        isClearable={true}
                                    ></Select>
                                </div> */}
                            </div>


                        </div>

                        <div className="card-footer text-end">
                            <button className="btn btn-primary" id="btnSearch" onClick={updateSearchFilters}>
                                Search
                            </button>
                        </div>
                    </>
                )}

            </div>
        </>
    );

}

export default ExpenseAdvancedSearch