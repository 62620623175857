import axios, {AxiosResponse} from 'axios'
import {ID, PaginationFilter} from '../../../../../../_metronic/helpers/crud-helper/models'
import {TransactionHistory, Vendor, VendorQueryResponse} from './_models'
import {Result} from '../../../../../core/models/Result'
const API_URL = process.env.REACT_APP_STORE_API_URL
const Vendor_LIST_URL = `${API_URL}/vendors/search`
const Vendor_BY_ID_URL = `${API_URL}/vendors`
const Vendor_ADD_URL = `${API_URL}/vendors`
const Transaction_History_URL = `${API_URL}/vendors/transactionhistory`
const Vendor_Record_URL = `${API_URL}/vendors/record`
const Bank_List_URL = `${API_URL}/bank/search`

const getVendorList = async (filter: PaginationFilter): Promise<VendorQueryResponse> => {
  return await axios
    .post(`${Vendor_LIST_URL}`, filter)
    .then((d: AxiosResponse<VendorQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const getVendorById = async (id: ID): Promise<Result | Vendor> => {
  return axios
    .get(`${Vendor_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<Vendor>) => response.data)
    .then((response: Vendor) => response)
    .catch((err: Result) => {
      return err
    })
}
const createVendor = async (vendor: Vendor): Promise<Result> => {
  return await axios
    .post(Vendor_ADD_URL, {
      firstName: vendor.firstName?.trim(),
      lastname: vendor.lastName?.trim(),
      companyName: vendor.companyName?.trim(),
      printName: vendor.printName?.trim(),
      alias: vendor.aadharNumber?.trim(),
      contactNumber: vendor.contactNumber?.trim(),
      faxNumber: vendor.faxNumber?.trim(),
      emailAddress: vendor.emailAddress?.trim(),
      website: vendor.website?.trim(),
      contactPerson: vendor.contactPerson?.trim(),
      mobileNumber: vendor.mobileNumber?.trim(),
      whatsappNumber: vendor.whatsappNumber?.trim(),
      lstNumber: vendor.lstNumber?.trim(),
      cstNumber: vendor.cstNumber?.trim(),
      gstNumber: vendor.gstNumber?.trim(),
      panNumber: vendor.panNumber?.trim(),
      aadharNumber: vendor.aadharNumber?.trim(),
      gstTreamentId: vendor.gstTreamentId,
      creditPeriodInDays: vendor.creditPeriodInDays,
      creditLimitInAmount: vendor.creditLimitInAmount,
      discountPercent: vendor.discountPercent,
      applyGST: vendor.applyGST,
      rateId: vendor.rateId,
      isInternal: vendor.isInternal,
      tenantTypeId: vendor.tenantTypeId,
      linkedTenantId: vendor.linkedTenantId,
      billingAddress: vendor.billingAddress,
      dateOfBirth: vendor.dateOfBirth,
      dateOfAnniversary: vendor.dateOfAnniversary,
      shippingAddress: vendor.shippingAddress,
      placeOfSupplyId: vendor.placeOfSupplyId,
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err
    })
}

const updateVendor = (vendor: Vendor): Promise<Result> => {
  return axios
    .put(`${Vendor_BY_ID_URL}/${vendor.id}`, {
      id: vendor.id,
      firstName: vendor.firstName?.trim(),
      lastname: vendor.lastName?.trim(),
      companyName: vendor.companyName?.trim(),
      printName: vendor.printName?.trim(),
      alias: vendor.aadharNumber?.trim(),
      contactNumber: vendor.contactNumber?.trim(),
      faxNumber: vendor.faxNumber?.trim(),
      emailAddress: vendor.emailAddress?.trim(),
      website: vendor.website?.trim(),
      contactPerson: vendor.contactPerson?.trim(),
      mobileNumber: vendor.mobileNumber?.trim(),
      whatsappNumber: vendor.whatsappNumber?.trim(),
      lstNumber: vendor.lstNumber?.trim(),
      cstNumber: vendor.cstNumber?.trim(),
      gstNumber: vendor.gstNumber?.trim(),
      panNumber: vendor.panNumber?.trim(),
      aadharNumber: vendor.aadharNumber?.trim(),
      gstTreamentId: vendor.gstTreamentId,
      creditPeriodInDays: vendor.creditPeriodInDays,
      creditLimitInAmount: vendor.creditLimitInAmount,
      discountPercent: vendor.discountPercent,
      applyGST: vendor.applyGST,
      rateId: vendor.rateId,
      isInternal: vendor.isInternal,
      tenantTypeId: vendor.tenantTypeId,
      linkedTenantId: vendor.linkedTenantId,
      billingAddress: vendor.billingAddress,
      dateOfBirth: vendor.dateOfBirth,
      dateOfAnniversary: vendor.dateOfAnniversary,
      shippingAddress: vendor.shippingAddress,
      isActive: vendor.isActive,
      placeOfSupplyId: vendor.placeOfSupplyId,
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}

const deleteVendor = async (id: ID): Promise<Result> => {
  return axios
    .delete(`${Vendor_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err
    })
}

const getRecordById = async (id: ID): Promise<Result | Vendor> => {
  return axios
    .get(`${Vendor_Record_URL}/${id}`)
    .then((response: AxiosResponse<Vendor>) => response.data)
    .then((response: Vendor) => response)
    .catch((err: Result) => {
      return err
    })
}

const transactionHistory = async (id: ID): Promise<Result | TransactionHistory> => {
  return axios
    .get(`${Transaction_History_URL}/${id}`)
    .then((response: AxiosResponse<TransactionHistory>) => response.data)
    .then((response: TransactionHistory) => response)
    .catch((err: Result) => {
      return err
    })
}

export {
  getVendorList,
  getVendorById,
  createVendor,
  updateVendor,
  deleteVendor,
  getRecordById,
  transactionHistory,
}
