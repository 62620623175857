import React, { useEffect, useState } from 'react'
import { AgentPayout } from '../core/_models'
import { getAgentPayoutList } from '../core/_requests'
import { KTIcon, PaginationFilter } from '../../../../../_metronic/helpers';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';

import { PaymentForm } from './payment/PaymentForm';
import { formatCurrency } from '../../../../core/utils/helpers';


const paginationFilter: PaginationFilter = {
    pageNumber: 1,
    pageSize: 500,

};
const AgentPayoutPage = () => {
    const { id } = useParams()
    const [payoutList, setPayoutList] = useState<AgentPayout[]>([])
    const [showModal, updateShowModal] = useState<boolean>(false)
    const navigate = useNavigate()
    useEffect(() => {
        const fetchCommission = async () => {
            let result: any
            const updatedPaginationFilter: PaginationFilter = {
                ...paginationFilter,
                advancedFilter: {
                    field: 'agentId',
                    operator: 'eq',
                    value: parseInt(id as string),
                },

            };
            await getAgentPayoutList(updatedPaginationFilter).then((v) => {
                result = v.data as AgentPayout[]
                setPayoutList(result)
            })
        }

        fetchCommission()
    }, [])

    const formatDate = (rowData: any, dateFormat: string) => {
        const date = new Date(rowData);
        return format(date, dateFormat);
    };

    const openAddUserModal = () => {
        // setItemIdForUpdate(undefined)
        updateShowModal(true)
    }

    return (
        <div className="card">
            {!showModal ? (
                <><div className="card-header">

                    <div className="card-title">
                        <h3>Order History</h3>
                        <button type='button' className='btn btn-primary position-absolute end-0 me-4' onClick={openAddUserModal}>
                            <KTIcon iconName='plus' className='fs-2' />
                            Add
                        </button>
                    </div>

                </div>
                    <div className="card-body pb-5">
                        <div className="table-responsive">
                            <table
                                className="table border table-striped table-row-bordered gy-5 gs-5"
                                id="kt_table_agentcommission"
                            >
                                <thead className="">
                                    <tr className="fw-semibold fs-6 text-gray-800">
                                        <th
                                            className="min-w-100px sorting"
                                            tabIndex={0}
                                            aria-controls="kt_table_agentcommission"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Agent Name.: activate to sort column ascending"
                                            style={{ width: "144.578px" }}
                                        >
                                            Agent Name
                                        </th>
                                        <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="kt_table_agentcommission"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Amount Paid: activate to sort column ascending"
                                            style={{ width: "122.688px" }}
                                        >
                                            Amount Paid
                                        </th>
                                        <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="kt_table_agentcommission"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Account Name: activate to sort column ascending"
                                            style={{ width: "118.375px" }}
                                        >
                                            Account Name
                                        </th>

                                        <th
                                            className="min-w-100px sorting_disabled"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Date"
                                            style={{ width: "227.188px" }}
                                        >
                                            Payment Date
                                        </th>
                                        <th
                                            className="min-w-100px sorting_disabled"
                                            rowSpan={1}
                                            colSpan={1}

                                            style={{ width: "227.188px" }}
                                        >
                                            Payment Mode
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="fw-semibold text-gray-600">
                                    {payoutList?.length > 0 ?
                                        (payoutList?.map((order) => (
                                            <tr onClick={() => { navigate(`/sales/sale-order/detail/${order.agentId}`); }} style={{ cursor: 'pointer' }}>
                                                <td><a className="text-gray-600 text-hover-primary mb-1">{order.agentName}</a></td>
                                                <td>{order.totalAmountPaid ? formatCurrency(order.totalAmountPaid) : '₹ 0.0'}</td>
                                                <td>{order.paymentDate ? formatDate(order.paymentDate, 'dd MMM yyyy') : '-'}</td>
                                                <td>{order.paymentModeName} </td>
                                            </tr>
                                        ))) : <tr>
                                            <td colSpan={5}>
                                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                    No matching records found
                                                </div>
                                            </td>
                                        </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </>

            ) : (showModal && <PaymentForm />
            )
            }
        </div>
    )
}

export default AgentPayoutPage