import { FieldInputProps, FieldProps, FormikProps, FormikValues } from "formik";
import React, { useState } from "react";
import { MultiValue, SingleValue } from "react-select";
import { PropsValue } from 'react-select'
import { SelectOptionProps } from "../../models/SelectOptionProps";
import AsyncSelect from 'react-select/async';
import { PaginationFilter, Response, IDto, getKeyValue } from "../../../../_metronic/helpers/crud-helper/models";


interface Option {
  label: string;
  value: string;
}

interface CustomAsyncSelectProps<T extends IDto> extends FieldProps {
  //loadOptions?: (inputValue: string, callback: (options: Options<Option>) => void) => Promise<Options<Option>> | void;  
  defaultOptions: SelectOptionProps[];
  // onChange?: (newValue: SingleValue<Option> | MultiValue<Option>) => void;
  onDropDownChange?: (newValue: SingleValue<Option> | MultiValue<Option>) => void;

  isMulti?: boolean;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  defaultInputValue?: string
  loadOptionFunc: (paginationFilter: PaginationFilter) => Response<Array<T>>;
  searchByField?: string;
  valueField?: string;
  labelField?: string;
  disabledisActiveFilter?: boolean;
}

export function CustomAsyncSelect<T extends IDto>({
  className,
  placeholder,
  field,
  form,
  onDropDownChange,
  //onChange,
  defaultOptions,
  defaultInputValue,
  loadOptionFunc,
  isMulti = false,
  disabled = false,
  searchByField,
  disabledisActiveFilter = false,
  valueField = 'id',
  labelField = 'name',

}: CustomAsyncSelectProps<T>) {

  const [options, setOptions] = useState<SelectOptionProps[]>(defaultOptions);

  const loadOptions = (
    inputValue: string,
    callback: (options: SelectOptionProps[]) => void
  ) => {
    if(inputValue == '' && defaultInputValue != undefined && defaultInputValue != ''){
      inputValue = defaultInputValue as string;
    }

    setTimeout(async () => {
      callback(await fetchData(inputValue, 10));
    }, 100);
  };


  const fetchData = async (inputValue: string, pageSize: number = 500) => {

    try {
      let paginationFilter: PaginationFilter = {
        pageNumber: 1,
        pageSize: pageSize

      };

      let isActiveFilter = { field: 'isActive', operator: 'eq', value: true }

      if (searchByField == undefined) {
        if (disabledisActiveFilter) {
          paginationFilter.advancedFilter = {
            field: 'name',
            operator: 'contains',
            value: inputValue,
          }
        }
        else {
          paginationFilter.advancedFilter = {
            logic: 'and',
            filters: [isActiveFilter,
              {
                field: 'name',
                operator: 'contains',
                value: inputValue,
              }]

          }
        }
      }
      else {
        if (disabledisActiveFilter) {
          paginationFilter.advancedFilter = {
            field: searchByField,
            operator: 'startswith',
            value: inputValue,
          }
        }
        else {
          paginationFilter.advancedFilter = {
            logic: 'and',
            filters: [isActiveFilter,
              {
                field: searchByField,
                operator: 'startswith',
                value: inputValue,
              }]
          }
        }
      }
      
      const response = await loadOptionFunc(paginationFilter);

      const result = response.data; //as DefaultParams[];
      let valueKey = valueField == undefined ? 'id' : valueField;
      let labelKey = labelField == undefined ? 'name' : labelField;
      let options: SelectOptionProps[] = [];

      if (result != undefined) {
        options = result.map((item) => ({
          value: getKeyValue(valueKey as never)(item) as number,
          label: getKeyValue(labelKey as never)(item) as string
        } as SelectOptionProps))
      }

      setOptions(options);
      return options;
    }
    catch (error) {
      console.error('Error loading :', error);
      return [];
    }
  }

  const onSelectChange = (option: PropsValue<Option>, isMulti: boolean, field: FieldInputProps<any>, form: FormikProps<FormikValues>) => {
    form.setFieldValue(
      field.name,
      isMulti
        ? (option as Option[]).map((item: Option) => item.value)
        : (option as Option).value
    );
  
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option: { value: any; }) => field.value && field.value.indexOf(option.value) >= 0)
        : options.find((option: { value: any; }) => option.value === field.value);
    } else {
      return isMulti ? [] : ("" as any);
    }
  };

  return (
    <AsyncSelect
      cacheOptions
      className={className}
      name={field.name}
      //value={field.value}
      value={getValue()}
      defaultInputValue={defaultInputValue}
      //onChange={onChange}
      onChange={(e: any) => {

        if (onDropDownChange == undefined) {
          onSelectChange(e, isMulti, field, form)
        }
        else {
          onDropDownChange(e)
        }
      }}
      placeholder={placeholder}
      loadOptions={loadOptions}
      defaultOptions={true}
      isMulti={isMulti}
      isDisabled={disabled}
      menuPosition="fixed"

    />

  );
}
export default CustomAsyncSelect;
