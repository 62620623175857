import { Link, Navigate, useNavigate } from 'react-router-dom'
import { KTIcon } from '../../../../../../_metronic/helpers'
import { MustHavePermission, SWSection, SWResource, SWAction } from '../../../../../core/shared/core/permissionUtils'






const InvoiceListToolbar = () => {

  const openAddUserModal = () => {      
    navigate('/sales/invoice/add')  

  }
  const navigate = useNavigate();
  return (
    <>
      {/* <div className="m-0">
        <SalesOrderListFilter />
      </div> */}
      {MustHavePermission(SWSection.Store, SWResource.Invoices, SWAction.Create) ?

      <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
        <KTIcon iconName='plus' className='fs-2' />
        Add
      </button>
:null}
    </>
  )
}

export { InvoiceListToolbar }
