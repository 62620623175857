import React, { FC, useState } from 'react'
import Select from 'react-select'
import { Filter, initialQueryState, } from '../../../../../../_metronic/helpers';
import { useQueryRequest } from '../../core/QueryRequestProvider';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { InvoiceListToolbar } from './InvoiceListToolbar';
import { format } from 'date-fns'
import { toZonedTime } from "date-fns-tz";

interface Props {
    onSearch: (searchParams: { keyword: string | undefined, advancedFilter: Filter | Filter[] | undefined }) => void;
}

const InvoiceAdvancedSearch: FC<Props> = ({ onSearch }) => {
    const [advancedSearchVisible, setAdvancedSearchVisible] = useState(false);

    const { state, updateState } = useQueryRequest()
    const [filterStatus, updateFilterStatus] = useState<boolean | undefined>()
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined)
    const [filterName, updateFilterName] = useState<string>('')
    const [filterInvoiceNumber, updateFilterInvoiceNumber] = useState<string>('')
    const [filterOrderDateFrom, setFilterOrderDateFrom] = useState<string | undefined | Date>(undefined);
    const [filterOrderDateTo, setFilterOrderDateTo] = useState<string | undefined | Date>(undefined);

    const invoiceStatusOptions = [
        { value: 1, label: 'Created' },
        { value: 2, label: 'Confirmed' },
        { value: 3, label: 'Cancelled' },
        { value: 4, label: 'Partially Packed' },
        { value: 5, label: 'Fully Packed' },
        { value: 6, label: 'Partially Delivered' },
        { value: 7, label: 'Delivered' },
    ]


    const updateSearchFilters = () => {
        let filters: Filter[] = []
        if (filterStatus != undefined) {
            filters.push({
                field: 'orderStatusId',
                operator: 'eq',
                value: filterStatus
            })
        }

        if (filterName != undefined && filterName != '') {
            filters.push({
                field: 'saleorder.customer.firstName',
                operator: 'contains',
                value: filterName
            })
        }



        if (filterInvoiceNumber != undefined && filterInvoiceNumber != '') {
            filters.push({
                field: 'invoiceNumber',
                operator: 'contains',
                value: filterInvoiceNumber
            })
        }

        if (filterOrderDateFrom || filterOrderDateTo) {

            let orderDateFilters: Filter[] = [];

            if (filterOrderDateFrom !== undefined) {
                const fromDate = toZonedTime(new Date(filterOrderDateFrom as Date), 'Asia/Kolkata');

                orderDateFilters.push({
                    field: 'invoiceDate',
                    operator: 'gte',
                    value: format(fromDate, 'yyyy-MM-dd 00:00:00'),

                });
            }

            if (filterOrderDateTo) {
                const toDate = toZonedTime(new Date(filterOrderDateTo as Date), 'Asia/Kolkata');

                orderDateFilters.push({
                    field: 'invoiceDate',
                    operator: 'lte',
                    value: format(toDate, 'yyyy-MM-dd 23:59:59'),

                });
            }
            filters.push({
                filters: orderDateFilters,
                logic: 'and',
            });
        }


        if (filters.length > 1) {
            updateState({
                keyword: searchTerm,
                advancedFilter: {
                    filters: filters,
                    logic: 'and'
                }, ...initialQueryState
            })
        }

        else if (filters.length === 1) {
            updateState({
                keyword: searchTerm,
                advancedFilter: filters[0],
                ...initialQueryState
            })
        }
        else {
            updateState({
                keyword: searchTerm,
                advancedFilter: undefined,
                ...initialQueryState
            })
        }

        onSearch({
            keyword: searchTerm,
            advancedFilter: filters.length > 1 ? { filters, logic: 'and' } : filters.length === 1 ? filters[0] : undefined,
        });
    }




    const handleDateChange = (selectedDates: Date[], dateName: string) => {
        if (dateName === 'from') {
            if (selectedDates[0] === undefined) {
                setFilterOrderDateFrom('')
            }
            else {
                setFilterOrderDateFrom(selectedDates[0]?.toDateString());
            }

        } else if (dateName === 'to') {
            if (selectedDates[0] === undefined) {
                setFilterOrderDateTo('')
            }
            else {
                setFilterOrderDateTo(selectedDates[0]?.toDateString());
            }
        }

    };



    return (
        <>

            <div className="card mb-5">
                <div className='card-header'>
                    <div className='card-title justify-content-between w-100'>
                        <h3>Invoice Filter</h3>
                        <button className="btn"
                        onClick={() => setAdvancedSearchVisible(!advancedSearchVisible)}>
                        {
                            advancedSearchVisible ?
                                <img src='/media/icons/up-angle.svg' alt='Filter' height={25} width={25} />
                                :
                                <img src='/media/icons/down-angle.svg' alt='Filter' height={25} width={25} />
                        }
                    </button>
                    </div>
                </div>
                {advancedSearchVisible && (
                    <>
                        <div className="card-body">

                            {/* <div className="row">
                            <div className="col-lg-6">
                                <div className="input-group">
                                    <i className="ki-duotone ki-magnifier fs-3 text-gray-500 position-absolute top-50 translate-middle ms-5 me-5">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i>
                                    <input
                                        type="text"
                                        className="form-control form-control-solid ps-10"
                                        id="txtsearchText"
                                        placeholder="Search Invoice"
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                    <button className="btn btn-primary" id="btnSearch" onClick={updateSearchFilters}>
                                        Search
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <button
                                    className="btn btn-link"
                                    onClick={() => setAdvancedSearchVisible(!advancedSearchVisible)}
                                >
                                    Advanced Search
                                </button>
                            </div>
                            <div className='col-lg-3 '>
                                <InvoiceListToolbar />
                            </div>
                        </div>
                     */}

                            <div className="collapse" id="kt_advanced_search_form">
                                <div className="separator separator-dashed mt-9 mb-6"></div>
                            </div>


                            <div className="row g-5">

                                <div className="col-lg-4">
                                    <label className="fs-6 form-label fw-bold text-dark" >
                                        Invoice Number
                                    </label>

                                    <input type="text" className="form-control" placeholder="Enter Invoice Number" autoComplete='off' name="packageNumber"
                                        value={filterInvoiceNumber} onChange={(e) => updateFilterInvoiceNumber(e.target.value)} />
                                </div>


                                <div className="col-lg-4">
                                    <label className="fs-6 form-label fw-bold text-dark" >
                                        Customer Name
                                    </label>

                                    <input type="text" className="form-control" placeholder="Enter Customer Name" autoComplete='off' name="firstName"
                                        value={filterName} onChange={(e) => updateFilterName(e.target.value)} />
                                </div>
                                <div className="col-lg-4">
                                    <label className="fs-6 form-label fw-bold text-dark">
                                        Invoice Date
                                    </label>
                                    <div className="row g-3">
                                        <div className="col">
                                            <Flatpickr
                                                options={{ dateFormat: 'Y-m-d' }}
                                                onChange={(selectedDates) => handleDateChange(selectedDates, 'from')}
                                                className="form-control form-control-solid"
                                                placeholder="From"
                                                name="invoiceDate"
                                            />
                                        </div>
                                        <div className="col">
                                            <Flatpickr
                                                options={{ dateFormat: 'Y-m-d' }}
                                                onChange={(selectedDates) => handleDateChange(selectedDates, 'to')}
                                                className="form-control form-control-solid"
                                                placeholder="To"
                                                name="invoiceDate"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="col-lg-4">
                                        <label className="fs-6 form-label fw-bold text-dark">
                                            Status
                                        </label>
                                        <Select
                                            className='form-select-solid fw-bolder'
                                            name="statusId"
                                            options={invoiceStatusOptions}
                                            placeholder="Select Status"
                                            onChange={(e) => {
                                                if (e != null) {
                                                    updateFilterStatus(e?.value === 1)
                                                }
                                                else {
                                                    updateFilterStatus(undefined)
                                                }
                                            }}
                                            isClearable={true}
                                        ></Select>
                                    </div> */}
                            </div>


                        </div>
                        <div className='card-footer text-end'>
                            <button className="btn btn-primary" id="btnSearch" onClick={updateSearchFilters}>
                                Search
                            </button>
                        </div>
                    </>
                )}

            </div>
        </>
    );

}

export default InvoiceAdvancedSearch