import { useNavigate } from 'react-router-dom'
import { useListView } from '../../../core/ListViewProvider'
import { KTIcon } from '../../../../../../../_metronic/helpers'

const PaymentListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddUserModal = () => {
     
    console.log("in modal")
    setItemIdForUpdate(null)
  }
const navigate=useNavigate()
  return (
    <>
      {/* <div className='m-0'>
        <PaymentListFilter />
      </div> */}
      <button type='button' className='btn btn-primary' onClick={()=>{navigate('/sales/payment/add-payment')}}>
          <KTIcon iconName='plus' className='fs-2' />
          Add
        </button>
    </>
  )
}

export {PaymentListToolbar}
