import React, { FC, useEffect } from 'react'
import { Result } from '../../../../core/models/Result';
import { Expense } from '../core/_models';
import { formatCurrency, formatDate } from '../../../../core/utils/helpers';



type Props = {

    data: Result | Expense;
};

const ExpenseDetailForm: FC<Props> = ({ data, }) => {
    const expense = data as Expense

    return (

        <div className="card pt-4 mb-2 mb-xl-9">

            <div className="card-header">
                <div className="card-title">
                    <h3>Expense Details</h3>
                </div>
            </div>

            <div className="card-body">
                <div className="row row-cols-1 row-cols-md-2">
                    <div className="col-xl-4 col-lg-6 col-md-6">
                        <div className="fv-row mb-3 fv-plugins-icon-container">
                            <label className="fs-5 fw-bold">{expense.vendorId === null ? 'Staff Name' : 'Vendor Name'} :
                                <span className='fs-6 fw-semibold'>   {expense?.vendorId === null ? expense?.staffName : expense?.vendorName}</span>
                            </label>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6">
                        <div className="fv-row mb-3 fv-plugins-icon-container">
                            <label className="fs-5 fw-bold">Expense Category :
                                <span className='fs-6 fw-semibold'>  {expense?.expenseCategory}</span>
                            </label>
                        </div>
                    </div>


                    <div className="col-xl-4 col-lg-6 col-md-6">
                        <div className="fv-row mb-3 fv-plugins-icon-container">
                            <label className="fs-5 fw-bold">Expense Date :
                                <span className='fs-6 fw-semibold'> {formatDate(expense?.expenseDate, 'dd MMM yyyy')}</span>
                            </label>
                        </div>
                    </div>


                    <div className="col-xl-4 col-lg-6 col-md-6">
                        <div className="fv-row mb-7 fv-plugins-icon-container">
                            <label className="fs-5 fw-bold">Expense Amount :
                                <span className='fs-6 fw-semibold'> {formatCurrency(expense?.expenseAmount)}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>





    )
}

export default ExpenseDetailForm