import React, {FC} from 'react'
import {ID, KTIcon} from '../../../../../../_metronic/helpers'
import {
  MustHavePermission,
  SWAction,
  SWResource,
  SWSection,
} from '../../../../../core/shared/core/permissionUtils'
import {useNavigate} from 'react-router-dom'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {deletePaymentMade} from '../../_request'

type Props = {
  id: ID
  status: ID
}

const PMActionsCell: FC<Props> = ({id}) => {
  const navigate = useNavigate()

  const queryClient = useQueryClient()
  const {mutate: handleDeletePaymentMade} = useMutation({
    mutationFn: (id: ID) => deletePaymentMade(id),
    onSuccess: () => {
      toast.success('Payment Made deleted successfully')
      queryClient.invalidateQueries({queryKey: ['getPaymentMadeRecords']})
    },
    onError: (error: any) => {
      toast.error(error.message)
    },
  })
  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a>

      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        <>
          {MustHavePermission(SWSection.Store, SWResource.PurchaseOrders, SWAction.View) ? (
            <div
              className='menu-item px-3'
              onClick={() => {
                // console.log(`/purchase/payment-made/detail/${id}`)
                navigate(`/purchase/payment-made/detail/${id}`)
              }}
            >
              <a className='menu-link px-3'>Detail</a>
            </div>
          ) : null}

          {MustHavePermission(SWSection.Store, SWResource.PurchaseOrders, SWAction.View) ? (
            <div className='menu-item px-3' onClick={() => handleDeletePaymentMade(id)}>
              <a className='menu-link px-3'>Delete</a>
            </div>
          ) : null}
        </>
      </div>
    </>
  )
}

export {PMActionsCell}
