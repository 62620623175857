import { FC, useEffect, useState } from 'react'
import { useImmer } from 'use-immer';
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import CustomSelect from '../../../../core/shared/components/CustomSelect';
import { Result } from '../../../../core/models/Result';
import { camelize } from '../../../../core/utils/StringHelpers';
import { PaginationFilter } from '../../../../../_metronic/helpers/crud-helper/models';
import { OtherCharges, SalesOrder, SalesOrderItems } from '../core/_models';
import {Field, FormikProvider, useFormik } from 'formik';
import { createSalesOrder } from '../core/_requests';
import { getColorList, getPolishingTypeList,  } from '../../../../core/shared/core/_request';
import clsx from 'clsx';
import { KTSVG } from '../../../../../_metronic/helpers';
import { Loading } from '../../../../core/shared/components/Loading';
import { toast } from 'react-toastify';
import { getCustomerById, getCustomerList } from '../../customer/customer-list/core/_requests';
import CustomAsyncSelect from '../../../../core/shared/components/CustomAsyncSelect';
import { Product } from '../../../catalog/product/product-list/core/_models';
import { Customer, CustomerDetailDto } from '../../customer/customer-list/core/_models';
import Flatpickr from 'react-flatpickr';
import { getCustomerVendor } from '../../../settings/core/_requests';
import { CustomerVendor } from '../../../settings/core/_models';
import ProductSearchbar from '../../../../core/shared/components/ProductSearchbar';

import { createSOPayment } from '../../salesOrder/salesOrder-list/core/_requests';

const paginationFilter: PaginationFilter = {
    pageNumber: 1,
    pageSize: 500,
    advancedFilter: {
        field: 'isActive', operator: 'eq', value: true
    }
};

const editSalesOrderSchema = Yup.object().shape({
    customerId: Yup.number().required('Please select customer'),
    orderDate: Yup.date().required('Please enter Sales Order Date.'),
    expectedShipmentDate: Yup.date().required('Please enter expected shipment date'),
    notes: Yup.string(),
    isAlteration: Yup.bool(),
})
const initialValues = {
    discountValue: 0,
    customerId: undefined,
    orderDate: new Date(),
    dueDate:new Date(),
    expectedShipmentDate: new Date(),
    notes: '',
    subTotal: 0,
    adjustment: 0,
    roundOff: 0,
    orderTotal: 0,
}

const POSAddPage: FC = () => {
    const [saleOrderItems, updateSaleOrderItems] = useImmer<SalesOrderItems[]>([]);
    const [otherCharges, setOtherCharges] = useImmer<OtherCharges[]>([])
    const[orderTotal,setOrderTotal]=useState<number>()
    const [errorMessage, updateErrorMessage] = useState<string>('')
    const [dateState, setDateState] = useState<any>({
        date1: new Date(),
        date2: new Date()
    });
    const [paymentDetails, setPaymentDetails] = useState({
        paymentDate: new Date(),
        amountReceived: 0,
        paymentModeId: 1,
        bankName: '',
        chequeNumber: 0,
        chequeDate: new Date(),

    });

  
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: editSalesOrderSchema,
        onSubmit: async (formValues, { setFieldError, setSubmitting }) => {
            setSubmitting(true)
            try {
                // Validations
                // 1. Atleast one product should be present
                // 2. Total Should be greater than 0.
                // 3. Line Item Should have valid product Id
                updateErrorMessage('')
                if (saleOrderItems.filter((item) => !item.isDeleted).length == 0) {
                    updateErrorMessage('Alteast one product is required')
                    return;
                }
                if (saleOrderItems.filter((item) => !item.isDeleted && item.productId == 0).length > 0) {
                    updateErrorMessage('Select Valid Product')
                    return;
                }
                if (saleOrderItems.filter((item) => !item.isDeleted && item.quantity == 0).length > 0) {
                    updateErrorMessage('Please ensure that the quantity cannot be zero')
                    return;
                }
                if (formValues.orderTotal <= 0) {
                    updateErrorMessage('Please ensure that the total amount is greater than or equal to zero.')
                    return;
                }
                let result: Result
                let saleOrder: SalesOrder = {
                    customerId: formValues.customerId,
                    orderDate: formValues.orderDate,
                    expectedShipmentDate: formValues.expectedShipmentDate,
                    notes: formValues.notes,
                    subTotal: formValues.subTotal,
                    adjustment: formValues.adjustment,
                    roundOff: formValues.roundOff,
                    orderTotal: formValues.orderTotal,
                    discountValue: formValues.discountValue,
                    dueDate:formValues.orderDate,
                    otherCharges:otherCharges.filter((item)=>!item.isDeleted),
                    itemList: saleOrderItems.filter((item) => !item.isDeleted)
                }
                result = await createSalesOrder(saleOrder)

                if (result.hasOwnProperty('succeeded') && result?.succeeded) {
                    setSubmitting(true)
                    createSOPayment({
                        saleOrderId:result.data,
                        amountReceived:paymentDetails.amountReceived,
                        paymentDate:paymentDetails.paymentDate,
                        paymentModeId:paymentDetails.paymentModeId
                    })
                    toast.success('Sale order created successfully!');
                    navigate('/sales/sale-order/list')
                }
                else {
                    if (result.statusCode === 400) {
                        result.propertyResults.map((error) =>
                            setFieldError(camelize(error.propertyName), error.errorMessage)
                        )
                    }
                    toast.error('An error occurred while saving the product.');
                }
            } catch (ex) {
                console.error(ex)
            }
        },
    })

    const [defaultCustomer, setDefaultCustomer] = useState<CustomerVendor>()

    useEffect(() => {
        getCustomerVendor().then((v) => {
            let customersettings = v as CustomerVendor;
            formik.setFieldValue('customerId', customersettings.defaultCustomerId)
        })
    }, []);

    useEffect(() => {
        calculateTotal()
    }, [saleOrderItems, otherCharges]);



    const onProductsChange = (e: Product) => {
        updateSaleOrderItems((soitems) => {
            var soItem = soitems.find(p => p.productId == e.id);
            if (soItem !== undefined) {
                soItem.quantity = (soItem.quantity as number) + 1;
            }
            else {
                let unitPrice: number = 0, discountAmount: number = 0;
                if (customerData?.rateId === 1) {
                    unitPrice = e.spWholeSeller as number;
                }
                else if (customerData?.rateId === 2) {
                    unitPrice = e.spSemiWholeSeller as number;
                }
                else {
                    unitPrice = e.spRetail as number;
                }

                discountAmount = unitPrice * (customerData?.discountPercent as number) / 100;

                soitems.push({
                    rowNumber: soitems.length,
                    polishingTypeName: e.polishingTypeName,
                    colorName: e.colorName,
                    productId: e.id,
                    productName: e.name,
                    quantity: 1,
                    unitPrice: unitPrice,
                    discountPercent: customerData?.discountPercent,
                    discountAmount: discountAmount,
                    totalPrice: (unitPrice - discountAmount) * 1,
                    isDeleted: false
                });
            }
        })
    };

    const [customerData, setCustomerData] = useState<Customer>()
    const onCustomerChange = (e: any) => {
        getCustomerById(e.value).then((v) => {
            const result = v as CustomerDetailDto;
            setCustomerData(result)
        })
        updateSaleOrderItems([])
    }

    function useFetchAndMapData(getDataFunction: any, mapFunction: any) {
        const [dataList, setDataList] = useState([]);
        useEffect(() => {
            getDataFunction(paginationFilter).then((response: { data: any; }) => {
                const result = response.data;
                const mappedData = result.map((item: any) => mapFunction(item));
                setDataList(mappedData);
            });
        }, []);

        return dataList;
    }

    const colorList = useFetchAndMapData(getColorList, (item: any) => ({
        value: item.id,
        label: item.name,
    }));

    const polishingTypeList = useFetchAndMapData(getPolishingTypeList, (item: any) => ({
        value: item.id,
        label: item.name,
    }));


    const navigate = useNavigate();

    const calculateTotal = () => {

        let tempTotal = 0;
        let total = 0;
        let subTotal = 0;
        let otherChargesTotal: number = 0;

        saleOrderItems.filter(t => !t.isDeleted).map((item) => {
            subTotal = subTotal + (item.totalPrice as number);
        })

        otherCharges.filter(t => !t.isDeleted).map((item) => {
            if (item.sign === 1) {
                otherChargesTotal = otherChargesTotal + item.value;
            } else if (item.sign === 2) {
                otherChargesTotal = otherChargesTotal - item.value;
            }
        })

        let discount = 0
        if (customerData?.discountPercent) {
            discount = customerData.discountPercent
            //setDisPercent(discount)
            formik.setFieldValue('discountPercent', discount)
            discount = (subTotal * discount) / 100;
            //setDiscountValue(discount)
            formik.setFieldValue('discountAmount', discount)
        }

        tempTotal = subTotal + otherChargesTotal;

        total = Math.round(tempTotal)
        formik.setFieldValue('subTotal', subTotal);
        formik.setFieldValue('roundOff', total - tempTotal);
        formik.setFieldValue('orderTotal', total);
        setOrderTotal(total)
    };
    const sign = [
        { value: 1, label: '+' },
        { value: 2, label: '-' },

    ]
    const updateItemTotal = (rowNumber: number) => {
        updateSaleOrderItems((soitems) => {

            const soItem = soitems.find(t => t.rowNumber === rowNumber);

            if (soItem != null) {
                let unitPrice: number = soItem.unitPrice as number;
                let qty: number = soItem.quantity as number;
                let discPercent: number = soItem.discountPercent as number;
                let total: number = 0, discAmt: number = 0;

                if (discPercent > 0) {
                    discAmt = (unitPrice * qty) * discPercent / 100
                }

                total = (unitPrice * qty) - discAmt;
                soItem.discountAmount = discAmt;
                soItem.totalPrice = total;
            }

        });
    }
    const totalDiscountAmount = () => {
        let totalDiscount: number = 0;
        saleOrderItems.forEach((soitem) => {
            totalDiscount += (soitem.discountAmount || 0);
        });
        return totalDiscount;
    }

    const handleAddRow = () => {
        setOtherCharges((ocitems) => {
            ocitems.push({
                rowNumber: ocitems.length,
                value: 0,
                isDeleted: false
            })
        })

    };



    // Function to handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPaymentDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    // Function to handle saving changes
    const handleSaveChanges = () => {
        // Access paymentDetails state here and perform any necessary actions
            console.log(paymentDetails);
           // setPaymentDetails(paymentDetails)
        // Reset the state after saving changes if needed
        // setPaymentDetails({
        //     paymentDate: new Date(),
        //     amountReceived: 0,
        //     paymentMode: 1,
        //     bankName: '',
        //     chequeNumber: 0,
        //     chequeDate: new Date(),

        // });
    };
    const PaymentModeOptions = [
        { label: 'Cash', value: 1 },
        { label: 'Cheque', value: 2 },
        { label: 'UPI', value: 3 },
        { label: 'Bank Transfer', value: 4 },

    ];


    const handlePaymentModeChange = (e) => {
        const selectedPaymentMode = parseInt(e.target.value, 10);

        setPaymentDetails({
            ...paymentDetails,
            paymentModeId: selectedPaymentMode,
        });
    };

    return (
        <FormikProvider value={formik}>
            <form
                id='kt_modal_add_product_form'
                className='form'
                onSubmit={formik.handleSubmit}
                noValidate
            >
                <div
                    className='d-flex flex-column flex-row-fluid"'
                    id='kt_modal_add_product_scroll'
                    data-kt-scroll='true'
                >
                    <div className="d-flex flex-column flex-row-fluid gap-4 gap-lg-10 mb-7">
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="kt_ecommerce_add_product_general" role="tab-panel">
                                {errorMessage != '' && (
                                    <div className='alert alert-danger'>
                                        {errorMessage}
                                    </div>
                                )}

                                <div className="d-flex flex-column gap-5 gap-lg-5">
                                    <div className="card card-flush">
                                        <div className="card-header">
                                            <div className="card-title">
                                                <h2>Order Details</h2>
                                            </div>

                                        </div>
                                        <div className="card-body pt-0">
                                            <div className='row mb-0'>
                                                <div className="col-6 col-md-6 ">
                                                    <label className="form-label required">Customer</label>

                                                    <Field
                                                        className='form-select-solid'
                                                        component={CustomAsyncSelect<Customer>}
                                                        defaultOptions={[
                                                            {
                                                                value: defaultCustomer?.defaultCustomerId,
                                                                label: defaultCustomer?.defaultCustomerName,
                                                            },
                                                        ]}

                                                        defaultInputValue={defaultCustomer?.defaultCustomerName}
                                                        // {...formik.getFieldProps(`customerId`)}
                                                        name={`customerId`}
                                                        loadOptionFunc={getCustomerList}
                                                        searchByField="printName"
                                                        labelField="printName"
                                                        onDropDownChange={(newValue: any) => {
                                                            onCustomerChange(newValue)
                                                            formik.setFieldValue('customerId', newValue?.value)

                                                        }}  
                                                        disabledisActiveFilter={true}
                                                    ></Field>
                                                    <div className="text-muted fs-7 ">Select the Customer.</div>
                                                    {formik.touched.customerId && formik.errors.customerId && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                <span role='alert'>{formik.errors.customerId}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="col-4 col-md-4 ">
                                                    <label className="form-label required">Order Date</label>
                                                    <div>
                                                        <Flatpickr

                                                            className='form-control'
                                                            placeholder='Pick date'
                                                            {...formik.getFieldProps('orderDate')}
                                                            name={'orderDate'}
                                                            // disabled={true}
                                                            //value={dateState.date1}
                                                            onChange={([date1]) => {
                                                                setDateState({ date1 });
                                                            }}

                                                        />
                                                    </div>
                                                    <div className="text-muted fs-7 ">Set the date of the order to process.</div>
                                                    {formik.touched.orderDate && formik.errors.orderDate && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                {/* <span role='alert'>{formik.errors.orderDate}</span> */}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-column flex-row-fluid gap-5 gap-lg-5">
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="kt_ecommerce_add_product_general" role="tab-panel">
                                <div className="d-flex flex-column gap-5 gap-lg-5">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="card-title">
                                                <h2>Item Details</h2>
                                            </div>
                                        </div>
                                        <div className="card-body pt-0">
                                            <ProductSearchbar
                                                onSelect={onProductsChange}
                                            />
                                            {/* <div className='row mb-8 '>
                                                <input className={clsx('form-control')} type='text' placeholder='Search Product'></input> 
                                                 <i className="bi bi-search fs-2 react-search-icon position-absolute top-0 bottom-0 d-flex align-items-center ms-2" />
                                                
                                            </div> */}
                                        </div>
                                        <div className="card-body pt-0">
                                            <div>
                                                <table className='table table-bordered'>
                                                    <thead>
                                                        <th className='text-gray-400 fs-5 fw-semibold mb-3'>Product</th>

                                                        <th className='text-gray-400 fs-5 fw-semibold mb-3'>Quantity</th>
                                                        <th className='text-gray-400 fs-5 fw-semibold mb-3'>Unit Price</th>
                                                        <th className='text-gray-400 fs-5 fw-semibold mb-3'>Total Price</th>
                                                        <th className='text-gray-400 fs-5 fw-semibold mb-3'>Actions</th>

                                                    </thead>
                                                    <tbody>
                                                        {saleOrderItems.filter(t => !t.isDeleted).map((soitem, index) => (
                                                            <tr key={soitem.rowNumber}>
                                                                <td>
                                                                    <p>{soitem.productName}</p>
                                                                    <p>{soitem.polishingTypeName}</p>
                                                                    <p>{soitem.colorName}</p>
                                                                </td>

                                                                <td>
                                                                    <Field
                                                                        type='text'
                                                                        className={clsx('form-control')}
                                                                        onChange={(e: any) => {
                                                                            updateSaleOrderItems(soitems => {
                                                                                const saleOrderItem = soitems.find(t => t.rowNumber == soitem.rowNumber)
                                                                                if (saleOrderItem != undefined) {
                                                                                    saleOrderItem.quantity = e.target.value;
                                                                                    updateItemTotal(soitem.rowNumber);


                                                                                }
                                                                            });
                                                                        }}
                                                                        name={`saleOrderItems[${soitem.rowNumber}].quantity`}
                                                                        value={saleOrderItems[soitem.rowNumber].quantity}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Field
                                                                        type='text'
                                                                        className={clsx('form-control')}
                                                                        onChange={(e: any) => {
                                                                            updateSaleOrderItems(soitems => {
                                                                                const saleOrderItem = soitems.find(t => t.rowNumber == soitem.rowNumber)
                                                                                if (saleOrderItem != undefined) {
                                                                                    saleOrderItem.unitPrice = e.target.value;
                                                                                    updateItemTotal(soitem.rowNumber);
                                                                                }
                                                                            });
                                                                        }}
                                                                        name={`saleOrderItems[${soitem.rowNumber}].unitPrice`}
                                                                        value={saleOrderItems[soitem.rowNumber].unitPrice}
                                                                    />
                                                                </td>
                                                                <td>

                                                                    <Field
                                                                        type='number'
                                                                        className={clsx('form-control')}
                                                                        name={`saleOrderItems[${soitem.rowNumber}].totalPrice`}
                                                                        value={saleOrderItems[soitem.rowNumber].totalPrice}
                                                                        disabled={true}
                                                                    />
                                                                </td>
                                                                <td className="text-center">
                                                                    <button
                                                                        type='button'
                                                                        data-repeater-delete=''
                                                                        className='btn btn-sm btn-icon btn-light-danger mt-2'
                                                                        onClick={(e: any) => {
                                                                            updateSaleOrderItems(soitems => {
                                                                                soitems[soitem.rowNumber].isDeleted = true
                                                                            });
                                                                        }}
                                                                    >
                                                                        <i className='ki-duotone ki-cross fs-1'>
                                                                            <span className='path1' />
                                                                            <span className='path2' />
                                                                        </i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>


                                            </div>
                                            <div className='separator mb-7'></div>
                                            <div className='row mt-4'>
                                                <div className='col-6 col-md-6 mb-3'>
                                                    <label className='fw-bold fs-6 mb-2 ms-1'>Note</label>

                                                    <textarea
                                                        rows={3}
                                                        placeholder='Note'
                                                        {...formik.getFieldProps('notes')}
                                                        name='notes'
                                                        className={clsx(
                                                            'form-control form-control-solid mb-3 mb-lg-0 ps-4 ms-1',
                                                            { 'is-invalid': formik.touched.notes && formik.errors.notes },
                                                            {
                                                                'is-valid': formik.touched.notes && !formik.errors.notes,
                                                            }
                                                        )}
                                                        autoComplete='off'
                                                        disabled={formik.isSubmitting}
                                                    />
                                                    {formik.touched.notes && formik.errors.notes && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                <span role='alert'>{formik.errors.notes}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className='mt-4 ms-3'>

                                                        <button type="button" className="btn btn-success" data-bs-toggle="modal" data-bs-target="#paymentModal">
                                                            Pay Now
                                                        </button>

                                                        <div className="modal fade" id="paymentModal" tabIndex={-1} aria-labelledby="paymentModalLabel" aria-hidden="true">
                                                            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable mw-800px">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h2 className="modal-title" id="paymentModalLabel">Payment Details</h2>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <div className="row mb-3">
                                                                            <div className='col-6'>
                                                                                <label className="form-label"> Received Amount:</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"

                                                                                    name="amountReceived"
                                                                                    value={paymentDetails.amountReceived}
                                                                                    onChange={handleInputChange}
                                                                                />
                                                                            </div>
                                                                            <div className='col-6'>
                                                                                <label className="form-label"> Paying Amount:</label>
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                   value={orderTotal}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mb-3">
                                                                            <div className='col-6'>
                                                                                <label className="form-label">Payment Date:</label>
                                                                                <Flatpickr
                                                                                    className='form-control'
                                                                                    placeholder='Pick date'
                                                                                    name={'paymentDate'}
                                                                                    disabled={true}

                                                                                    // onChange={([date2]) => {
                                                                                    //     setDateState({ date2 });
                                                                                    // }}
                                                                                    value={dateState.date2}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label className="form-label me-4">Payment Mode:</label>
                                                                            {PaymentModeOptions.map((option) => (
                                                                                <div key={option.value} className='form-check form-check-inline'>
                                                                                    <input
                                                                                        type='radio'
                                                                                        className='form-check-input'
                                                                                        name='paymentModeId'
                                                                                        value={option.value}
                                                                                        checked={paymentDetails.paymentModeId === option.value}
                                                                                        onChange={handlePaymentModeChange}
                                                                                    />
                                                                                    <label className='form-check-label'>{option.label}</label>
                                                                                </div>

                                                                            ))}

                                                                        </div>
                                                                        {/* {paymentDetails.paymentMode === 2 && (
                                                                            <div>

                                                                                <div className="row mb-3">
                                                                                    <div className='col-4'>
                                                                                        <label className="form-label me-4">Bank Name:</label>
                                                                                        <input
                                                                                            className='form-control'
                                                                                            type="text"
                                                                                            value={paymentDetails.bankName}
                                                                                            onChange={(e) => setPaymentDetails({ ...paymentDetails, bankName: e.target.value })} />
                                                                                    </div>
                                                                                    <div className="col-4">
                                                                                        <label className="form-label me-4">Cheque Number:</label>
                                                                                        <input
                                                                                            className='form-control'
                                                                                            type="number"
                                                                                            value={paymentDetails.chequeNumber}
                                                                                            onChange={(e) => setPaymentDetails({ ...paymentDetails, chequeNumber: parseInt(e.target.value) })} />
                                                                                    </div>
                                                                                    <div className="col-4">
                                                                                        <label className="form-label me-4">Cheque Date:</label>
                                                                                        <Flatpickr
                                                                                            className='form-control'
                                                                                            placeholder='Pick date'
                                                                                            name={'chequeDate'}
                                                                                            onChange={([date2]) => {
                                                                                                setDateState({ date2 });
                                                                                            }}
                                                                                            value={dateState.date2}
                                                                                        />

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )} */}


                                                                    </div>

                                                                    <div className="modal-footer">
                                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                                        <button type="button" className="btn btn-primary" onClick={handleSaveChanges} data-bs-dismiss='modal'>Save changes</button>

                                                                        {/* <button type="button" className="btn btn-primary" onClick={handleSaveChanges} dat-bs-dismiss='modal' >Save changes</button> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='col-6 col-md-6 mb-3 bg-light text-dark'>
                                                    <div className='d-flex justify-content-between mb-7'>
                                                        <label className='fs-4 mt-3'>Sub Total</label>
                                                        <span className='fs-4 mt-3 '>₹ {formik.values.subTotal?.toFixed(2)}</span>
                                                    </div>
                                                    <div className='separator mb-7'></div>

                                                    {otherCharges
                                                        .filter(t => !t.isDeleted)
                                                        .map((ocitem, index) => (
                                                            <><div className='row mb-3'
                                                                key={ocitem.rowNumber}
                                                            >
                                                                <div className='col-3'>
                                                                    <Field
                                                                        className='form-control'
                                                                        name={`otherCharges[${ocitem.rowNumber}].label`}
                                                                        type='text'
                                                                        onChange={(e: any) => {
                                                                            setOtherCharges((oc) => {
                                                                                oc[ocitem.rowNumber].label = e.target.value;
                                                                            })
                                                                        }}
                                                                    />

                                                                </div>
                                                                <div className='col-3'>
                                                                    <Field className='form-select-solid'
                                                                        options={sign}
                                                                        component={CustomSelect}
                                                                        name={`otherCharges[${ocitem.rowNumber}].sign`}
                                                                        onDropDownChange={(e: any) => {
                                                                            setOtherCharges((oc) => {
                                                                                oc[ocitem.rowNumber].sign = e.value;
                                                                            })
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className='col-3'>
                                                                    <Field
                                                                        type='number'
                                                                        name={`otherCharges[${ocitem.rowNumber}].value`}
                                                                        className={clsx('form-control me-10')}
                                                                        onChange={(e: any) => {
                                                                            setOtherCharges((oc) => {
                                                                                oc[ocitem.rowNumber].value = parseFloat(e.target.value);
                                                                            })
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className='col-3'>
                                                                    <button
                                                                        type='button'
                                                                        data-repeater-delete=''
                                                                        className='btn btn-sm btn-icon btn-light-danger mt-2'
                                                                        onClick={(e: any) => {
                                                                            setOtherCharges((oc) => {
                                                                                oc[ocitem.rowNumber].isDeleted = true;
                                                                            })
                                                                        }}

                                                                    >
                                                                        <i className='ki-duotone ki-cross fs-1'>
                                                                            <span className='path1' />
                                                                            <span className='path2' />
                                                                        </i>
                                                                    </button>
                                                                </div>

                                                            </div></>
                                                        ))}

                                                    <div className='form-group mt-5 mb-5'>
                                                        <button type='button'

                                                            className='btn btn-sm btn-light-primary'
                                                            onClick={handleAddRow}>
                                                            + Add another Charges
                                                        </button>
                                                    </div>

                                                    {customerData?.discountPercent ? <div className='d-flex justify-content-between mb-7'>

                                                        <label className='fs-4 mt-3'>Discount</label>

                                                        <span className='fs-4 mt-3 '>₹ {totalDiscountAmount()}</span>
                                                    </div> : ''}


                                                    <div className='d-flex justify-content-between mb-7'>
                                                        <label className='fs-4 mt-3'>Round Off</label>

                                                        <span className='fs-4 mt-3 '>{formik.values.roundOff?.toFixed(2)}</span>
                                                    </div>
                                                    <div className='separator mb-7'></div>
                                                    <div className='d-flex justify-content-between mb-7'>
                                                        <label className='fs-4 mt-3'>Total</label>
                                                        <span className='fs-4 mt-3 '>₹ {formik.values.orderTotal}
                                                           
                                                        </span>
                                                    </div>
                                                    <div className='d-flex justify-content-between mb-7'>
                                                        <label className='fs-4 mt-3'>Received Amount</label>
                                                        <span className='fs-4 mt-3 '>₹ {paymentDetails.amountReceived}
                                                           
                                                        </span>
                                                    </div>
                                                    <div className='d-flex justify-content-between mb-7'>
                                                        <label className='fs-4 mt-3'>Balance Amount</label>
                                                        <span className='fs-4 mt-3 '>₹ {formik.values.orderTotal - paymentDetails.amountReceived === 0 ? 'Nill' : (formik.values.orderTotal - paymentDetails.amountReceived)}
                                                           
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='d-flex justify-content-end'>
                            <button
                                type='reset'
                                className='btn btn-light me-3'
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_1"
                                disabled={formik.isSubmitting}
                            >
                                Discard
                            </button>
                            <div className="modal fade" tabIndex={-1} id="kt_modal_1">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h2>Confirmation</h2>
                                            <div
                                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                <KTSVG
                                                    path="/media/icons/duotune/arrows/arr061.svg"
                                                    className="svg-icon svg-icon-2x"
                                                />
                                            </div>
                                        </div>
                                        <div className="modal-body">
                                            <div className="w-100">

                                                {/* <div className="fv-row mb-10 d-flex justify-content-center exclamation fs-14">
												<i className="las la-exclamation-circle"></i>
											</div> */}

                                                <div className="fv-row mb-10 text-center">
                                                    <h5>Are you sure you want to discard ?</h5>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn btn-light"
                                                data-bs-dismiss="modal"
                                                onClick={() => navigate('/sales/sale-order/list')}
                                            >
                                                Yes
                                            </button>
                                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal">
                                                No
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-color-modal-action='submit'
                            //disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                            >
                                <span className='indicator-label'>Submit</span>
                                {(formik.isSubmitting) && (
                                    <span className='indicator-progress'>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </div>



                </div>
            </form>

            {(formik.isSubmitting) && <Loading />}
        </FormikProvider >
    )
}

export { POSAddPage }
