import {Field, FormikProvider, useFormik} from 'formik'
import * as Yup from 'yup'
import React, {useEffect, useState} from 'react'
import {ID, KTSVG} from '../../../../../_metronic/helpers'

import {
  getBillDetails,
  getOrganizatinProfileAddress,
  getVendorById,
  updateBillRecord,
} from '../_requests'
import Select from 'react-select'
import clsx from 'clsx'
import ProductSearchbar from '../../../../core/shared/components/ProductSearchbar'
import ProductSelection from '../../../../core/shared/components/ProductSelection'
import {useImmer} from 'use-immer'
import {BillItems, OtherCharges, PaginationFilter, Product, Vendor} from '../_models'
import {OrganizationProfileAddress} from '../../../settings/core/_models'
import {getOtherChargeList} from '../../../../core/shared/core/_request'
import CustomSelect from '../../../../core/shared/components/CustomSelect'
import {formatCurrency} from '../../../../core/utils/helpers'
import {useNavigate, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {Result} from '../../../../core/models/Result'
import {camelize} from '../../../../core/utils/StringHelpers'
import {useQuery} from 'react-query'
import {Calendar} from 'primereact/calendar'
import '../assets/CalenderStyle.css'
import {toZonedTime} from 'date-fns-tz'
import {format} from 'date-fns'
import Flatpickr from 'react-flatpickr'

const editBillSchema = Yup.object().shape({
  // vendorId: Yup.number().required('please select vendor'),
  billNumber: Yup.string().required('Please enter Bill number'),
  dueDate: Yup.date(),
  billDate: Yup.date().required('Please enter Bill Date.'),
  // billItemList: Yup.array().of(
  //   Yup.object().shape({
  //     quantity: Yup.number()
  //       .required('Please enter Quantity.')
  //       .min(0, 'Quantity to Receive cannot be negative'),
  //     unitPrice: Yup.number().required('Please enter Unit Price.').min(1, 'Unit Price cannot be 0'),
  //   })
  // ),
  notes: Yup.string(),
})

const BillEditPage = () => {
  const navigate = useNavigate()
  const {id} = useParams()

  const {isLoading: isBillDetailsLoading, data: result} = useQuery(
    ['getBillData'],
    async () => {
      return await getBillDetails(id)
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err)
      },
    }
  )

  const [initialValues, setInitialValues] = useState({
    vendorId: 0,
    billDate: new Date(),
    dueDate: new Date(),
    billNumber: 'undefined',
    subTotal: 0,
    roundOff: 0,
    orderTotal: 0,
    billItemList: [] as Product[],
  })

  useEffect(() => {
    if (!isBillDetailsLoading && result) {
      setInitialValues({
        vendorId: result.vendorId,
        billDate: new Date(result.billDate),
        dueDate: new Date(result.dueDate),
        billNumber: result.billNumber,
        subTotal: 0,
        roundOff: 0,
        orderTotal: 0,
        billItemList: result.itemList.map((item) => ({
          ...item,
          unitPrice: item.productPrice || 0,
        })) as Product[],
      })

      formik.setFieldValue('vendorId', result.vendorId)
      formik.setFieldValue('billDate', new Date(result.billDate))
      formik.setFieldValue('dueDate', new Date(result.dueDate))
      formik.setFieldValue('billNumber', result.billNumber)
    }
  }, [isBillDetailsLoading, result])

  const [vendorData, setVendorData] = useState<Vendor>()
  const [billItems, updateBillItems] = useImmer<BillItems[]>([])
  const [otherCharges, setOtherCharges] = useImmer<OtherCharges[]>([])
  const [errorMessage, updateErrorMessage] = useState<string>('')

  const [selectedAddress, setSelectedAddress] = useState<{
    value: string
    label: string
    id: number
  } | null>(null)
  const [addresses, setAddresses] = useState<{
    data: OrganizationProfileAddress[]
    pagination: any
  }>()

  const handleApiError = (
    result: Result,
    setFieldError: (field: string, message: string) => void
  ) => {
    if (result.statusCode === 400) {
      result.propertyResults.forEach((error) =>
        setFieldError(camelize(error.propertyName), error.errorMessage)
      )
    }
    toast.error('An error occurred while saving the purchase order.')
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: editBillSchema,
    onSubmit: async (formValues, {setFieldError, setSubmitting}) => {
      setSubmitting(true)
      try {
        // Validations
        updateErrorMessage('')
        const validateBillItems = () => {
          const hasProducts = billItems?.filter((item) => !item.isDeleted).length > 0
          const hasInvalidQuantity =
            billItems.filter((item) => item?.quantity !== undefined && item.quantity <= 0).length >
            0
          const hasInvalidUnitPrice =
            billItems.filter((item) => item?.unitPrice !== undefined && item.unitPrice <= 0)
              .length > 0

          if (!hasProducts) {
            window.scrollTo(0, 0)
            updateErrorMessage('At least one product is required')
            return false
          }

          if (hasInvalidQuantity) {
            window.scrollTo(0, 0)
            updateErrorMessage('Quantity cannot be 0')
            return false
          }

          if (hasInvalidUnitPrice) {
            updateErrorMessage('Unit Price cannot be 0')
            window.scrollTo(0, 0)
            return false
          }

          return true
        }

        if (!validateBillItems()) {
          return
        }

        const billDate = toZonedTime(formValues.billDate as Date, 'Asia/Kolkata')
        const dueDate = toZonedTime(formValues.dueDate as Date, 'Asia/Kolkata')
        const billDateString = format(billDate, 'yyyy-MM-dd')
        const dueDateString = format(dueDate, 'yyyy-MM-dd')

        const resultPayload = {
          id: id,
          vendorId: initialValues.vendorId,
          billDate: billDateString,
          roundOff: formValues.roundOff,
          orderTotal: formValues.orderTotal,
          orderSubTotal: formValues.subTotal,
          totalOtherCharges: otherCharges.reduce(
            (total, charge) => total + (charge.isDeleted ? 0 : charge.value || 0),
            0
          ),
          dueDate: dueDateString,
          billNumber: formValues.billNumber,
          deliveryAddressId: selectedAddress?.id || 0,
          billItemList: billItems.map((item) => ({
            productId: item.productId,
            quantity: item.quantity,
            productPrice: item.unitPrice,
            subTotal: item.total,
            isDeleted: item.isDeleted,
            billItemId: item.billItemId || undefined,
          })),
          otherCharges: otherCharges.map((charge) => ({
            id: charge.id || 0,
            label: charge.label,
            amount: charge.value,
            isDeleted: charge.isDeleted,
          })),
        }

        const result = await updateBillRecord(resultPayload)
        if (result.hasOwnProperty('succeeded') && result?.succeeded) {
          toast.success('Your bill has been updated successfully!')
          navigate('/purchase/bills/list')
        } else {
          handleApiError(result, setFieldError)
        }
      } catch (error) {
        console.error('Error during form submission:', error)
      } finally {
        setSubmitting(false)
      }
    },
  })

  // Vendor handling
  const onVendorChange = async (e: any) => {
    try {
      const vendor = await getVendorById(initialValues?.vendorId)
      setVendorData(vendor as Vendor)
    } catch (error) {
      console.error('Error fetching vendor:', error)
    }
  }

  const calculateTotal = () => {
    let subTotal: number = 0
    let otherChargesTotal: number = 0

    billItems.forEach((item) => {
      if (item.quantity && item.unitPrice) {
        subTotal += Number(item.quantity) * Number(item.unitPrice)
      }
    })

    otherCharges
      .filter((t) => !t.isDeleted)
      .forEach((item) => {
        otherChargesTotal = otherChargesTotal + (item.value || 0)
      })

    const tempTotal = subTotal + otherChargesTotal
    const total = Math.round(tempTotal)

    // Update formik values
    formik.setFieldValue('subTotal', subTotal)
    formik.setFieldValue('roundOff', total - tempTotal)
    formik.setFieldValue('orderTotal', total)
  }

  const fetchAddress = async () => {
    try {
      const response = await getOrganizatinProfileAddress()
      const addressList = response as OrganizationProfileAddress[]
      setAddresses({
        data: addressList,
        pagination: {},
      })

      // Set default address if available
      if (addressList && addressList.length > 0) {
        const defaultAddress = addressList[0]
        setSelectedAddress({
          value: defaultAddress.displayAddress ?? '',
          label: defaultAddress.displayAddress ?? '',
          id: defaultAddress.id,
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (formik.values.vendorId) {
      onVendorChange(formik.values.vendorId)
    }

    fetchAddress()
  }, [formik.values.vendorId])

  //change delivery address
  const handleAddressChange = (selected: {value: string; label: string; id: number} | null) => {
    setSelectedAddress(selected)
  }

  const onProductsChange = (product: Product, quantity: number = 1) => {
    updateBillItems((items) => {
      const existingItem = items.find((p) => p.productId === product.id)
      const unitPrice =
        formik.values.billItemList?.find((item) => item.productId === product.id)?.unitPrice ||
        product.unitPrice ||
        0
      if (existingItem && existingItem.quantity !== undefined) {
        existingItem.quantity += quantity
        existingItem.total = unitPrice * existingItem.quantity
        formik.setFieldValue(
          `billItemList[${existingItem.rowNumber}].quantity`,
          existingItem.quantity
        )
      } else {
        const newItem = {
          rowNumber: items.length,
          productId: product.id as number,
          productName: product.name,
          quantity: quantity,
          unitPrice: unitPrice,
          total: unitPrice * quantity,
          isDeleted: false,
        }
        items.push(newItem)
        formik.setFieldValue(`billItemList[${newItem.rowNumber}].quantity`, newItem.quantity)
      }
    })
  }

  // Update handleQuantityChange to ensure validation works correctly
  const handleQuantityChange = (productId: ID, newValue: number) => {
    updateBillItems((draft) => {
      const item = draft.find((item) => item.productId === productId)
      if (item) {
        const quantity = newValue || 0
        item.quantity = quantity
        item.total = item.unitPrice ? item.unitPrice * quantity : 0
        // Ensure formik state is updated for validation
        formik.setFieldValue(`billItemList[${item.rowNumber}].quantity`, quantity)
      }
    })
  }

  const handleDelete = (e: React.ChangeEvent<HTMLInputElement>, productId: ID) => {
    updateBillItems((draft) => {
      return draft.filter((item) => item.productId !== productId)
    })
  }
  const isValidNumberString = (str: string | number) => /^-?\d+(\.\d+)?$/.test(String(str))

  const handleOtherChargeValueChange = (index: number, value: number) => {
    setOtherCharges((draft) => {
      draft[index].value = value
    })

    if (!isValidNumberString(value)) {
      // setErrorMessages((prev) => ({...prev, [index]: 'Invalid input: Not a valid number'}))
      return
    }
  }

  const handleAddRow = () => {
    setOtherCharges((ocitems) => {
      ocitems.push({
        rowNumber: ocitems.length,
        value: 0,
        isDeleted: false,
      })
    })
  }

  const paginationFilter: PaginationFilter = {
    pageNumber: 1,
    pageSize: 500,
  }

  function useFetchAndMapData(getDataFunction: any, mapFunction: any) {
    const [dataList, setDataList] = useState([])
    useEffect(() => {
      getDataFunction(paginationFilter).then((response: {data: any}) => {
        const result = response.data
        const mappedData = result.map((item: any) => mapFunction(item))
        setDataList(mappedData)
      })
    }, [])

    return dataList
  }

  const chargesList = useFetchAndMapData(getOtherChargeList, (item: any) => ({
    value: item.id,
    label: item.name,
  }))

  useEffect(() => {
    calculateTotal()
  }, [billItems, otherCharges])

  // Populate billItems from result.itemList
  useEffect(() => {
    if (result) {
      updateBillItems(
        result.itemList.map((item, index) => ({
          rowNumber: index,
          billItemId: item?.billItemId,
          productId: item?.productId,
          productName: item?.productName,
          quantity: item?.quantity,
          unitPrice: item?.productPrice,
          total: item?.subTotal,
          isDeleted: false,
        }))
      )
    }
  }, [result])

  const billRecordErrors = formik.errors?.billItemList as Array<{
    quantity?: string
    unitPrice?: string
  }>
  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} className='form'>
        {errorMessage != '' && <div className='alert alert-danger'>{errorMessage}</div>}
        <div className='d-flex flex-column flex-xl-row gap-5 gap-lg-5 mb-10'>
          <div className='card flex-row-fluid'>
            <div className='card-header'>
              <div className='card-title w-100'>
                <div className='row mt-3 w-100 justify-content-between'>
                  <label className='col-6 col-form-label fw-bold fs-4'>Bill Details</label>
                </div>
              </div>
              <div className='card-body'>
                <div className='row align-items-center'>
                  <div className='col-xl-2 col-lg-3 col-md-4'>
                    <label className='form-label required'>Vendor</label>
                  </div>
                  <div className='col-xl-6 col-lg-9 col-md-8'>
                    {result?.vendorName || 'vendorName'}
                  </div>
                </div>
                {result && (
                  <>
                    <div className='row mt-5'>
                      <div className='col-xl-2 col-lg-3 col-md-4'></div>
                      <div className='col-xl-10 col-lg-9 col-md-8'>
                        <div className='row '>
                          {/* Billing Address Column */}
                          <div className='col-4'>
                            <div className='d-flex align-items-center gap-20 mb-1'>
                              <h6 className='mb-1'>Vendor Address</h6>
                            </div>
                            <p className='mb-0'>
                              {vendorData?.billingAddress?.addressLine1 || result?.billAddressLine1}
                              <br />
                              {vendorData?.billingAddress?.addressLine2 || result?.billAddressLine2}
                              <br />
                              {vendorData?.billingAddress?.city || result?.billCityName} -{' '}
                              {vendorData?.billingAddress?.zipCode}
                              <br />
                              {vendorData?.billingAddress?.stateName || result?.billStateName}
                            </p>
                          </div>
                          {/* Shipping Address Column */}
                          <div className='col-4'>
                            <div className='d-flex align-items-center gap-20 mb-1'>
                              <h6 className='mb-1'>Delivery Address</h6>
                            </div>
                            <p className='mb-0'>
                              <Select
                                className='form-select-solid'
                                value={selectedAddress}
                                onChange={handleAddressChange}
                                options={(addresses?.data || []).map((address) => ({
                                  value: address.displayAddress || '',
                                  label: address.displayAddress || '',
                                  id: address.id,
                                }))}
                                formatOptionLabel={({value}) => (
                                  <div style={{whiteSpace: 'pre-line'}}>{value}</div>
                                )}
                                styles={{
                                  control: (base) => ({
                                    ...base,
                                    minHeight: '100px',
                                    backgroundColor: '#f5f8fa',
                                    border: '1px solid #e4e6ef',
                                    borderRadius: '0.475rem',
                                  }),
                                  option: (base) => ({
                                    ...base,
                                    padding: '10px',
                                    whiteSpace: 'pre-line',
                                  }),
                                  menu: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className='row mt-5 mb-0 align-items-center'>
                  <div className='col-xl-2 col-lg-3 col-md-4'>
                    <label className='form-label required'>Bill Date</label>
                  </div>
                  <div className='col-xl-3 col-lg-9 col-md-8'>
                    <Flatpickr
                      className='form-control'
                      placeholder='Pick date'
                      {...formik.getFieldProps('billDate')}
                      name={'billDate'}
                      options={{
                        dateFormat: 'd-m-Y',
                        altInput: true,
                        altFormat: 'd-m-Y',
                        allowInput: false,
                      }}
                      onChange={([date1]) => {
                        formik.setFieldValue('billDate', date1)
                      }}
                    />

                    {formik.touched.billDate && formik.errors.billDate && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'></div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row align-items-center mt-5'>
                  <div className='col-xl-2 col-lg-3 col-md-4'>
                    <label className='form-label required'>Due Date</label>
                  </div>
                  <div className='col-xl-3 col-lg-9 col-md-8'>
                    <div>
                      <Flatpickr
                        className='form-control'
                        placeholder='Pick date'
                        {...formik.getFieldProps('dueDate')}
                        name={'dueDate'}
                        value={formik.values.dueDate}
                        options={{
                          altInput: true,
                          altFormat: 'd-m-Y',
                          allowInput: false,
                        }}
                        onChange={([date1]) => {
                          formik.setFieldValue('dueDate', date1)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className='row mt-5 mb-0 align-items-center'>
                  <div className='col-xl-2 col-lg-3 col-md-4'>
                    <label className='form-label required'>Bill Number</label>
                  </div>
                  <div className='col-xl-3 col-lg-9 col-md-8'>
                    <Field
                      type='text'
                      className={clsx('form-control')}
                      {...formik.getFieldProps('billNumber')}
                      name={'billNumber'}
                      value={formik.values.billNumber || initialValues?.billNumber}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        formik.setFieldValue('billNumber', e.target.value)
                      }
                    />
                    {formik.touched.billNumber && formik.errors.billNumber && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.billNumber as string}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='card flex-row-fluid'>
          <div className='card-header'>
            <div className='card-title'>
              <h3>Item Details</h3>
            </div>
          </div>
          <div className='card-body'>
            <ProductSearchbar onSelect={onProductsChange} />
          </div>
          <div className='separator'></div>
          <ProductSelection onSelect={onProductsChange} />
          <div className='card-body pt-0'>
            <div className='selected-items-table flex-table py-4 px-4 border'>
              <table className='table'>
                <thead className='text-gray-600 fs-5 fw-semibold'>
                  <tr>
                    <th>Product</th>
                    <th>Quantity</th>
                    <th>Unit Price</th>
                    <th>Total</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {billItems
                    .filter((t) => !t.isDeleted)
                    .map((billitem, index) => (
                      <>
                        <tr key={billitem.rowNumber}>
                          <td>
                            <p>{billitem.productName}</p>
                          </td>
                          <td>
                            <Field
                              type='text'
                              className={clsx('form-control')}
                              {...formik.getFieldProps(`billItemList[${index}].quantity`)}
                              name={`billItemList[${index}].quantity`}
                              value={billitem?.quantity}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const newQuantity = parseInt(e.target.value) || 0
                                if (newQuantity >= 0) {
                                  handleQuantityChange(billitem.productId, newQuantity)
                                }
                              }}
                            />
                          </td>
                          <td>
                            <Field
                              type='text'
                              className={clsx('form-control')}
                              name={`billItemList[${index}].unitPrice`}
                              value={billitem.unitPrice}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const value = e.target.value
                                // Regular expression to allow negative numbers, numbers, and decimal points
                                const regex = /^[0-9]*\.?[0-9]*$/
                                if (regex.test(value) || value === '') {
                                  const newValue = parseFloat(value) || 0
                                  console.log(newValue)
                                  updateBillItems((draft) => {
                                    const item = draft.find(
                                      (item) => item.productId === billitem.productId
                                    )
                                    if (item) {
                                      item.unitPrice = newValue
                                      item.total = newValue * (item.quantity || 0)
                                    }
                                  })
                                } else {
                                  e.target.value = ''
                                }
                              }}
                            />
                          </td>
                          <td>
                            <Field
                              type='text'
                              className={clsx('form-control')}
                              name={`billItemList[${billitem.rowNumber}].total`}
                              value={
                                billitem.unitPrice
                                  ? billitem.unitPrice * (billitem.quantity || 0)
                                  : 0
                              }
                              readOnly
                            />
                          </td>
                          <td className='text-center'>
                            <button
                              className='btn btn-sm btn-icon btn-light-danger mt-2'
                              onClick={(e: any) => {
                                handleDelete(e, billitem.productId)
                              }}
                            >
                              <i className='ki-duotone ki-cross fs-1'>
                                <span className='path1' />
                                <span className='path2' />
                              </i>
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            {formik.touched?.billItemList &&
                              formik.touched?.billItemList[index] &&
                              billRecordErrors && (
                                <p className='text-danger'>{billRecordErrors[0]?.quantity}</p>
                              )}
                          </td>
                          <td>
                            {' '}
                            {formik.touched?.billItemList &&
                              formik.touched?.billItemList[index] &&
                              billRecordErrors && (
                                <p className='text-danger'>{billRecordErrors[0]?.unitPrice}</p>
                              )}
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                      </>
                    ))}
                  {billItems.length === 0 && (
                    <tr>
                      <td colSpan={5} className='text-center py-5'>
                        <img
                          src='/media/img/no-records.png'
                          width={80}
                          alt='No item'
                          className='no-item'
                        />
                        <h5 className='text-gray-600 mt-2'>No item Selected</h5>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className='row mt-4'></div>
            <div className='card flex-row-fluid overflow-hidden mt-5'>
              <div className='card-body pt-0'>
                <div className='row mb-7'>
                  <div className='col-xl-6 col-lg-12 order-xl-1 col-md-12 mb-3'></div>
                  <div className='col-xl-6 col-lg-12 order-xl-2 col-md-12 mt-7'>
                    <div className='px-5 py-2 bg-light text-dark border'>
                      <div className='d-flex justify-content-between align-items-center mb-2'>
                        <label className='fs-4 mt-3'>Sub Total</label>
                        <span className='fs-4 mt-3 '>
                          ₹{' '}
                          {formik?.values?.subTotal?.toLocaleString('en-IN', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </span>
                      </div>
                      <div className='separator mb-2'></div>
                      {otherCharges
                        .filter((t) => !t.isDeleted)
                        .map((ocitem, index) => (
                          <>
                            <div className='row mb-3 align-items-center' key={ocitem.rowNumber}>
                              <div className='col-4'>
                                <Field
                                  className='form-select-solid'
                                  options={chargesList}
                                  component={CustomSelect}
                                  name={`otherCharges[${ocitem.rowNumber}].label`}
                                  onDropDownChange={(newValue: any) => {
                                    if (newValue === null) {
                                      setOtherCharges((oc) => {
                                        oc[ocitem.rowNumber].label = ''
                                      })
                                    } else {
                                      setOtherCharges((oc) => {
                                        oc[ocitem.rowNumber].label = newValue.label
                                      })
                                    }
                                  }}
                                  isClearable={true}
                                  isMulti={false}
                                ></Field>
                              </div>

                              <div className='col-3 ps-0'>
                                <div className='input-group'>
                                  <Field
                                    type='text'
                                    min={0}
                                    name={`otherCharges[${ocitem.rowNumber}].value`}
                                    className={clsx('form-control text-end')}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      const value = e.target.value
                                      // Regular expression to allow negative numbers, numbers, and decimal points
                                      const regex = /^-?[0-9]*\.?[0-9]*$/
                                      if (regex.test(value) || value === '') {
                                        const newvalue = parseFloat(value) || 0
                                        handleOtherChargeValueChange(
                                          index,
                                          isNaN(newvalue) ? 0 : newvalue
                                        )
                                      } else {
                                        // If the value doesn't match, reset the input to the last valid value
                                        e.target.value = ''
                                      }
                                    }}
                                  />
                                </div>
                                <div>
                                  {/* <span className='alert-danger'>{errorMessages[index]}</span> */}
                                </div>
                              </div>

                              <div className='col-1 ps-0'>
                                <div className='d-flex align-items-center gap-2'>
                                  <i
                                    className='bi bi-question-circle-fill'
                                    data-bs-toggle='tooltip'
                                    data-bs-placement='top'
                                    title='Tooltip on top'
                                  ></i>
                                  <span
                                    className='text-danger cursor-pointer'
                                    onClick={() => {
                                      setOtherCharges((oc) => {
                                        oc[ocitem.rowNumber].isDeleted = true
                                      })
                                    }}
                                  >
                                    X
                                  </span>
                                </div>
                              </div>

                              <div className='col-4 text-right'>{formatCurrency(ocitem.value)}</div>
                            </div>
                          </>
                        ))}
                      <div className='form-group mt-5 mb-5'>
                        <button
                          type='button'
                          className='btn btn-sm btn-light-primary'
                          onClick={handleAddRow}
                        >
                          + Add another Charges
                        </button>
                      </div>
                      <div className='d-flex justify-content-between mb-2'>
                        <label className='fs-4 mt-3'>Round Off</label>

                        <span className='fs-4 mt-3 '>
                          ₹{' '}
                          {formik.values?.roundOff !== undefined &&
                          !Number.isNaN(Number(formik.values.roundOff))
                            ? Number(formik.values.roundOff).toFixed(2)
                            : '0.00'}
                        </span>
                      </div>
                      <div className='separator mb-2'></div>
                      <div className='d-flex justify-content-between mb-7'>
                        <label className='fs-4 mt-3'>Total</label>
                        <span className='fs-4 mt-3 '>
                          ₹{' '}
                          {formik.values.orderTotal !== undefined &&
                          !Number.isNaN(parseInt(formik.values.orderTotal.toString()))
                            ? formik.values.orderTotal?.toLocaleString('en-IN', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : '0.00'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card-footer text-end'>
                <button
                  type='reset'
                  className='btn btn-light me-3'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_1'
                  disabled={formik.isSubmitting}
                >
                  Discard
                </button>
                <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                  <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                      <div className='modal-header'>
                        <h2>Confirmation</h2>
                        <div
                          className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                          data-bs-dismiss='modal'
                          aria-label='Close'
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr061.svg'
                            className='svg-icon svg-icon-2x'
                          />
                        </div>
                      </div>
                      <div className='modal-body'>
                        <div className='w-100'>
                          <div className='fv-row mb-10 d-flex justify-content-center exclamation fs-14'>
                            <i className='las la-exclamation-circle'></i>
                          </div>

                          <div className='fv-row text-center'>
                            <h5>Are you sure you want to discard ?</h5>
                          </div>
                        </div>
                      </div>
                      <div className='modal-footer'>
                        <button
                          type='button'
                          className='btn btn-light'
                          data-bs-dismiss='modal'
                          onClick={() => navigate('/purchase/bills/list')}
                        >
                          Yes
                        </button>
                        <button type='button' className='btn btn-primary' data-bs-dismiss='modal'>
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-color-modal-action='submit'
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  <span className='indicator-label'>Submit</span>
                  {formik.isSubmitting && (
                    <span className='indicator-progress'>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormikProvider>
  )
}

export default BillEditPage
