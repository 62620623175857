import axios, {AxiosResponse} from 'axios'
import {
  BillAaddPayload,
  BillDetails,
  BillsListQueryResponse,
  PaginationFilter,
  PurchaseOrderQueryResponse,
  Vendor,
  VendorQueryResponse,
} from './_models'
import {Result} from '../../../core/models/Result'
import {ID} from '../../../../_metronic/helpers'
import {OrganizationProfileAddress} from '../../settings/core/_models'

const API_URL = process.env.REACT_APP_STORE_API_URL
const BillRecord_ADD_URL = `${API_URL}/bills`
const BILLS_LIST_URL = `${API_URL}/bills/search`
const Vendor_LIST_URL = `${API_URL}/vendors/search`
const Vendor_BY_ID_URL = `${API_URL}/vendors`
const OrganizationProfile_ADDRESS_SEARCH_URL = `${API_URL}/organizationprofile/address/search`
const Bill_DETAILS = `${API_URL}/bills`

const getBillDetails = async (id: ID): Promise<BillDetails> => {
  return await axios
    .get(`${Bill_DETAILS}/${id}`)
    .then((d) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const getVendorList = async (filter: PaginationFilter): Promise<VendorQueryResponse> => {
  return await axios
    .post(`${Vendor_LIST_URL}`, filter)
    .then((d: AxiosResponse<VendorQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const getBillsList = async (filter: PaginationFilter) => {
  return axios
    .post(`${BILLS_LIST_URL}`, filter)
    .then((d: AxiosResponse<BillsListQueryResponse>) => {
      return d.data
    })
    .catch((err) => {
      return err
    })
}

const getVendorById = async (id: ID): Promise<Result | Vendor> => {
  return axios
    .get(`${Vendor_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<Vendor>) => response.data)
    .then((response: Vendor) => response)
    .catch((err: Result) => {
      return err
    })
}

const getOrganizatinProfileAddress = async (): Promise<Result | OrganizationProfileAddress[]> => {
  return await axios
    .post(`${OrganizationProfile_ADDRESS_SEARCH_URL}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: {},
    })
    .then((d) => {
      return d.data?.data
    })
    .catch((err: Result) => {
      return err
    })
}

const createBillRecord = async (billRecord: BillAaddPayload): Promise<Result> => {
  return await axios
    .post(BillRecord_ADD_URL, billRecord)
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err
    })
}

const updateBillRecord = async (billRecord: any): Promise<Result> => {
  return await axios
    .put(`${BillRecord_ADD_URL}/${billRecord.id}`, billRecord)
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err
    })
}

export {
  getBillsList,
  getVendorList,
  getVendorById,
  getOrganizatinProfileAddress,
  getBillDetails,
  createBillRecord,
  updateBillRecord,
}
