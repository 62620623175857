
import { useEffect, useState } from 'react'
import { KTCard, KTCardBody, KTIcon, PaginationFilter } from '../../../../../_metronic/helpers'

import { useListView, ListViewProvider } from '../core/ListViewProvider'
import { QueryRequestProvider } from '../core/QueryRequestProvider'
import { QueryResponseProvider } from '../core/QueryResponseProvider'
import { ExpenseCategory } from '../core/_models'
import { getExpenseCategoryList } from '../core/_requests'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

import { ExpenseCategoryModal } from './ExpenseCategoryModal'
import { MustHavePermission, SWSection, SWResource, SWAction } from '../../../../core/shared/core/permissionUtils'

const paginationFilter: PaginationFilter = {
  pageNumber: 1,
  pageSize: 500,
}
const   ExpenseCategoryList = () => {
  const { itemIdForUpdate } = useListView()
  const [expenseCategory, setExpenseCategory] = useState<ExpenseCategory[]>([])
  const [globalFilterValue, setGlobalFilterValue] = useState<string>('');

  useEffect(() => {
    getExpenseCategoryList(paginationFilter).then((data) => {
      let result = data.data as ExpenseCategory[]
      setExpenseCategory(result)
    })
  }, [itemIdForUpdate])

  const filteredData = expenseCategory?.filter((item: ExpenseCategory) =>
    Object.values(item).some((value: string | boolean) =>
      typeof value === 'string' && value.toLowerCase().includes(globalFilterValue.toLowerCase())
    )
  );
  const { setItemIdForUpdate } = useListView()
  const openAddAccountModal = () => {
    setItemIdForUpdate(null)
  }

  const openEditAccountModal = (id: any) => {
    setItemIdForUpdate(id)
  }


  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="actions">
        <a
          href='#'
          className='btn btn-light btn-active-light-primary btn-sm '
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          onClick={() => { openEditAccountModal(rowData.id) }}
        >
          <span className="ki-duotone ki-pencil" >
            <span className="path1"></span>
            <span className="path2"></span>
          </span>

        </a>
      </div>
    );
  };

  const renderHeader = () => (

    <>
    <div className='w-100 d-flex align-items-center position-relative justify-content-between my-2'>
      <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-4' />
      <input
        type='text'
        data-kt-customer-table-filter='search'
        className='form-control form-control-solid w-250px ps-14'
        placeholder='Search Expense Category'
        value={globalFilterValue} onChange={(e) => setGlobalFilterValue(e.target.value)} />
      <button type='button' className='btn btn-primary' onClick={openAddAccountModal}>
        <KTIcon iconName='plus' className='fs-2' />
        Add
      </button>
    </div>
    </>
  );

  const header = renderHeader();
  return (
    <>
      <KTCard>
        <div className="card-header">
          {header}
        </div>
        <KTCardBody>
          <>
            <div className="d-flex justify-content-end mt-4">
              <div className="m-0">
              </div>
              {/* {MustHavePermission(SWSection.Store, SWResource.ExpenseCategories, SWAction.Create) ?

                <button type='button' className='btn btn-primary' onClick={openAddAccountModal}>
                  <KTIcon iconName='plus' className='fs-2' />
                  Add
                </button>
                :
                null} */}
            </div>
            <div>
              <DataTable
                className='table-responsive'
                tableClassName='table border table-striped table-row-bordered gy-5 gs-7'
                paginatorClassName='pagination'
                paginatorLeft
                value={filteredData}
                tableStyle={{ minWidth: '75rem' }}
                breakpoint='960px'
               // header={header}
                paginator
                rows={10}
                rowsPerPageOptions={[10,20,50]}
              >
                <Column
                  //className='text-gray-600 fw-bold'
                  headerClassName='fw-semibold fs-6 text-gray-800'
                  field='name'
                  header={ 'Expense Name'}
                  sortable
                  style={{ minWidth: '6rem' }}
                />
                {MustHavePermission(SWSection.Store, SWResource.ExpenseCategories, SWAction.Create) ?
                  <Column field="" 
                  headerClassName='fw-semibold fs-6 text-gray-800'
                  header={'Action'}
                  body={actionBodyTemplate} />
                  :
                  null}
              </DataTable>
            </div>
          </>
        </KTCardBody>
      </KTCard>
      {itemIdForUpdate !== undefined && <ExpenseCategoryModal />}
    </>
  )
}

const ExpenseCategoryWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ExpenseCategoryList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { ExpenseCategoryWrapper }
