import { useNavigate} from 'react-router-dom'
import {KTIcon} from '../../../../../../../../_metronic/helpers'

const PaymentListToolbar = () => {

  const navigate=useNavigate()

  return (
    <div className='d-flex align-items-center gap-2 gap-lg-3 justify-content-end'>
      {/* <div className='m-0'>        
      </div> */}
      <button type='button' className='btn btn-primary' onClick={()=>{navigate('/purchase/payment/add-payment')}}>
          <KTIcon iconName='plus' className='fs-2' />
          Add
        </button>
    </div>
  )
}

export {PaymentListToolbar}
