import React, { FC, useEffect, useRef } from 'react'
import { useImmer } from 'use-immer'
import { ImageInputComponent } from '../../../../_metronic/assets/ts/components'
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { FileUploadRequest } from '../../models/FileUploadRequest';

const imageinputplaceholder = {
    backgroundImage: `url(${toAbsoluteUrl('/media/svg/files//blank-image.svg')})`
};

interface Props {
    id?: string
    ImagePath?: string
    HandleUpload: (img: FileUploadRequest) => void;
    HandleRemove: () => void;
}

const UploadImage: FC<Props> = ({ id, ImagePath, HandleUpload, HandleRemove }) => {
    const API_URL = process.env.REACT_APP_IMAGE_API_URL
    if (id === undefined) {
        id = "kt_image_input_control"
    }

    var imageInputElement = document.querySelector(`#${id}`);
    var imageInput = ImageInputComponent.getInstance(imageInputElement as HTMLElement);

    imageInput?.on("kt.imageinput.removed", function () {
        HandleRemove()
        console.log("kt.imageinput.removed event is fired");
    });

    imageInput?.on("kt.imageinput.canceled", function () {
        HandleRemove()
        console.log("kt.imageinput.canceled event is fired");
    });

    imageInput?.on("kt.imageinput.changed", function (element: any) {
        handleImageUpload(element)
        console.log("kt.imageinput.changed event is fired");
    });


    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];

        if (file) {
          
            const allowedTypes = ['.jpeg','.jpg', '.png', '.webp'];

            const fileExtension = `.${file.type.split('/')[1]}`;
            if (!allowedTypes.includes(fileExtension)) {
                // if (imageRef.current) {
                //     imageRef.current.value = '';
                // }
                    e.target.value =''                
                console.log("Invalid format: Please select a valid image format ( JPG ,JPEG, PNG, or WebP).");
              
                return;

            }

            const reader = new FileReader()
            reader.onload = () => {
                const base64Image = reader.result as string
                const fileName = file.name;
                const nameWithoutExtension = fileName.split('.').slice(0, -1).join('.');
                HandleUpload({ name: nameWithoutExtension, extension: `.${file.type.split('/')[1]}`, data: base64Image })
            }

            reader.readAsDataURL(file)
        }
    }

 

    useEffect(() => {
        ImageInputComponent.bootstrap();
    }, []);

    return (
            <div style={imageinputplaceholder} id={id} className={`image-input image-input-outline image-input-placeholder mb-3 image-input-empty`} data-kt-image-input="true">
                <div className="image-input-wrapper w-125px h-125px" style={(ImagePath != null && API_URL != null) ? { backgroundImage: `url(${API_URL}/${ImagePath})` } : {}}></div>
                <label className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                    data-kt-image-input-action="change"
                    data-bs-toggle="tooltip"
                    data-bs-dismiss="click"
                    title="Change Image">
                    <i className="ki-duotone ki-pencil fs-6"><span className="path1"></span><span className="path2"></span></i>
                    {/* <input type="file" name="avatar"  onChange={handleImageUpload} ref={imageRef} accept='.jpg .jpeg ,webp' /> */}
                    <input
                        type="file"
                        name="avatar"
                        onChange={handleImageUpload}
                        //ref={imageRef}
                        accept=".jpg,.jpeg,.webp,.png" 
                    />
                    <input type="hidden" name="avatar_remove" />
                </label>
                <span className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                    data-kt-image-input-action="cancel"
                    data-bs-toggle="tooltip"
                    data-bs-dismiss="click"
                    title="Cancel Image">
                    <i className="ki-outline ki-cross fs-3"></i>
                </span>
                <span className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                    data-kt-image-input-action="remove"
                    data-bs-toggle="tooltip"
                    data-bs-dismiss="click"
                    title="Remove Image">
                    <i className="ki-outline ki-cross fs-3"></i>
                </span>
            </div>
    )
}

export default UploadImage;
