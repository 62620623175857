import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { Filter, initialQueryState, KTCard, KTCardBody, PaginationFilter } from '../../../../../../../_metronic/helpers';
import { SelectOptionProps } from '../../../../../../core/models/SelectOptionProps';
import { getPaymentModeList } from '../../../../../settings/paymode/core/_requests';
import { PaymentMode } from '../../../../../settings/paymode/core/_models';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { getCustomerList } from '../../../../customer/customer-list/core/_requests';
import { Customer } from '../../../../customer/customer-list/core/_models';
import { format } from 'date-fns'
import { toZonedTime } from "date-fns-tz";
import { useQueryRequest } from '../../../core/QueryRequestProvider';



const PaymentAdvancedSearch = () => {
    const [advancedSearchVisible, setAdvancedSearchVisible] = useState(false);
    const [customerList, setCustomerList] = useState<SelectOptionProps[]>([]);
    const [paymentModeList, setPaymentModeList] = useState<SelectOptionProps[]>([]);
    const { state, updateState } = useQueryRequest()
    const [filterStatus, updateFilterStatus] = useState<boolean | undefined>()
    const [filterCustomerId, updateFilterCustomerId] = useState<number[] | undefined>(undefined)
    const [filterPaymentModeId, updateFilterPaymentModeId] = useState<number[] | undefined>(undefined)
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined)
    const [filterPaymentDateFrom, setFilterPaymentDateFrom] = useState<string | undefined | Date>(undefined);
    const [filterPaymentDateTo, setFilterPaymentDateTo] = useState<string | undefined | Date>(undefined);


    const paginationFilter: PaginationFilter = {
        pageNumber: 1,
        pageSize: 500,
    };

    const [dateState, setDateState] = useState<any>({
        date1: new Date(),
        date2: new Date()
    });

    useEffect(() => {
        let result: any[] = []

        getCustomerList(paginationFilter).then((v) => {
            result = v.data as Customer[]
            const customerArray: any[] = []
            result.map((item: any) => {
                return customerArray.push({ value: item.id, label: item.printName })
            })
            setCustomerList(customerArray)
        })

        getPaymentModeList(paginationFilter).then((v) => {
            result = v.data as PaymentMode[]
            let paymentModeArray: any[] = []
            result.map((item: any) => {
                return paymentModeArray.push({ value: item.id, label: item.name })
            })
            setPaymentModeList(paymentModeArray)
        })



    }, [])

    const handleDateChange = (selectedDates: Date[], dateName: string) => {

        if (dateName === 'from') {
            if (selectedDates[0] === undefined) {
                setFilterPaymentDateFrom('')
            }
            else {

                setFilterPaymentDateFrom(selectedDates[0]?.toDateString());
            }

        } else if (dateName === 'to') {
            if (selectedDates[0] === undefined) {
                setFilterPaymentDateTo('')
            }
            else {

                setFilterPaymentDateTo(selectedDates[0]?.toDateString());
            }
        }

    };

    const updateSearchFilters = () => {

        let filters: Filter[] = []
        if (filterStatus != undefined) {
            filters.push({
                field: 'isActive',
                operator: 'eq',
                value: filterStatus
            })
        }

        if (filterCustomerId != undefined) {
            let customerFilter: Filter[] = []

            filterCustomerId.map((id) => {
                customerFilter.push({
                    field: 'customerId',
                    operator: 'eq',
                    value: id
                })
            })

            if (customerFilter.length > 1) {
                filters.push({
                    filters: customerFilter,
                    logic: 'or'
                })
            }
            else if (customerFilter.length == 1) {
                filters.push(customerFilter[0])
            }
        }

        if (filterPaymentModeId != undefined) {
            let paymentModeFilter: Filter[] = []

            filterPaymentModeId.map((id) => {
                paymentModeFilter.push({
                    field: 'paymentModeId',
                    operator: 'eq',
                    value: id
                })
            })

            if (paymentModeFilter.length > 1) {
                filters.push({
                    filters: paymentModeFilter,
                    logic: 'or'
                })
            }
            else if (paymentModeFilter.length == 1) {
                filters.push(paymentModeFilter[0])
            }
        }

        if (filterPaymentDateFrom || filterPaymentDateTo) {
            let paymentDateFilter: Filter[] = [];

            if (filterPaymentDateFrom !== undefined) {
                const fromDate = toZonedTime(new Date(filterPaymentDateFrom as Date), 'Asia/Kolkata');

                paymentDateFilter.push({
                    field: 'paymentDate',
                    operator: 'gte',
                    value: format(fromDate, 'yyyy-MM-dd 00:00:00'),
                });
            }

            if (filterPaymentDateTo !== undefined) {
                const toDate = toZonedTime(new Date(filterPaymentDateTo as Date), 'Asia/Kolkata');

                paymentDateFilter.push({
                    field: 'paymentDate',
                    operator: 'lte',
                    value: format(toDate, 'yyyy-MM-dd 23:59:59'),

                });
            }

            filters.push({
                filters: paymentDateFilter,
                logic: 'and',
            });
        }

        if (filters.length > 1) {
            updateState({
                keyword: searchTerm,
                advancedFilter: {
                    filters: filters,
                    logic: 'and'
                }, ...initialQueryState
            })
        }

        else if (filters.length === 1) {
            updateState({
                keyword: searchTerm,
                advancedFilter: filters[0],
                ...initialQueryState
            })
        }
        else {
            updateState({
                keyword: searchTerm,
                advancedFilter: undefined,
                ...initialQueryState
            })
        }
    }

    return (
        <>

            <KTCard className="mb-5">
                <div className="card-header justify-content-between align-items-center">
                    <div className="card-title">
                        <h3>Payment Filter</h3>
                    </div>
                    <button className="btn"
                        onClick={() => setAdvancedSearchVisible(!advancedSearchVisible)}>
                        {
                            advancedSearchVisible ?
                                <img src='/media/icons/up-angle.svg' alt='Filter' height={25} width={25} />
                                :
                                <img src='/media/icons/down-angle.svg' alt='Filter' height={25} width={25} />
                        }
                    </button>
                </div>
                {advancedSearchVisible && (
                    <>
                        <KTCardBody>
                            {/* <div className="row">
                        <div className="col-lg-6">
                            <div className="input-group">
                                <i className="ki-duotone ki-magnifier fs-3 text-gray-500 position-absolute top-50 translate-middle ms-5 me-5">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>
                                <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    id="txtsearchText"
                                    placeholder="Search Payment"
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <button className="btn btn-primary" id="btnSearch" onClick={updateSearchFilters}>
                                    Search
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <button
                                className="btn btn-link"
                                onClick={() => setAdvancedSearchVisible(!advancedSearchVisible)}
                            >
                                Advanced Search
                            </button>
                        </div>
                        <div className='col-lg-3 '>
                            <PaymentListToolbar />
                        </div>
                    </div> */}


                            <div className="collapse" id="kt_advanced_search_form">
                                <div className="separator separator-dashed mt-9 mb-6"></div>
                            </div>


                            <div className="row g-5">

                                <div className="col-lg-4">
                                    <label className="fs-6 form-label fw-bold text-dark" >
                                        Customer
                                    </label>
                                    <Select
                                        className='form-select-solid ms-1'
                                        name="vendorId"
                                        options={customerList}
                                        placeholder="Select Customer"
                                        onChange={(e) => updateFilterCustomerId((e as SelectOptionProps[]).map((item: SelectOptionProps) => item.value))}
                                        isClearable={true}
                                        isMulti={true}
                                    ></Select>
                                </div>
                                <div className="col-lg-4">
                                    <label className="fs-6 form-label fw-bold text-dark" >
                                        Payment Mode
                                    </label>
                                    <Select
                                        className='form-select-solid ms-1'
                                        name="paymentModeId"
                                        options={paymentModeList}
                                        placeholder="Select PaymentMode"
                                        onChange={(e) => updateFilterPaymentModeId((e as SelectOptionProps[]).map((item: SelectOptionProps) => item.value))}
                                        isClearable={true}
                                        isMulti={true}
                                    ></Select>
                                </div>

                                <div className="col-lg-4">
                                    <label className="fs-6 form-label fw-bold text-dark">
                                        Payment Date
                                    </label>
                                    <div className="row g-3">
                                        <div className="col">
                                            <Flatpickr
                                                //value={dateState.date1}
                                                options={{ dateFormat: 'Y-m-d' }}
                                                onChange={(selectedDates) => handleDateChange(selectedDates, 'from')}
                                                className="form-control form-control-solid"
                                                placeholder="From"
                                            />
                                        </div>
                                        <div className="col">
                                            <Flatpickr
                                                // value={dateState.date2}
                                                options={{ dateFormat: 'Y-m-d' }}
                                                onChange={(selectedDates) => handleDateChange(selectedDates, 'to')}
                                                className="form-control form-control-solid"
                                                placeholder="To"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </KTCardBody>
                        <div className="card-footer text-end">
                            <button className="btn btn-primary" id="btnSearch" onClick={updateSearchFilters}>
                                Search
                            </button>
                        </div>
                    </>
                )}

            </KTCard>
        </>
    );

}

export default PaymentAdvancedSearch