import React, {useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {isNotEmpty, KTCard, KTCardBody} from '../../../../../_metronic/helpers'
import {useQuery} from 'react-query'
import {getBillDetails} from '../_requests'
import {formatCurrency, formatDate} from '../../../../core/utils/helpers'
import {ReceivedBillItems} from '../_models'
import {Loading} from '../../../../core/shared/components/Loading'

const BillDetails = () => {
  const {id} = useParams()
  const {isLoading, data: result} = useQuery(
    ['getBillData'],
    async () => {
      return await getBillDetails(id)
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err)
      },
    }
  )
  const [activeTab, setActiveTab] = useState<string>('bill_summary')

  const initialValues = {
    id: result?.id,
    vendorId: result?.vendorId,
    vendorName: result?.vendorName,
    vendorEmail: result?.vendorEmail,
    vendorMobileNumber: result?.vendorMobileNumber,
    purchaseOrderId: result?.purchaseOrderId,
    purchaseOrderNumber: result?.purchaseOrderNumber,
    billNumber: result?.billNumber,
    billDate: result?.billDate,
    orderTotal: result?.orderTotal,
    paymentStatusId: result?.paymentStatusId,
    paymentStatusName: result?.paymentStatusName,
    billStatusId: result?.billStatusId,
    billStatusName: result?.billStatusName,
    vendorAddressLine1: result?.billAddressLine1,
    vendorAddressLine2: result?.billAddressLine2,
    vendorCityName: result?.billCityName,
    vendorStateId: result?.billStateId,
    vendorStateName: result?.billStateName,
    vendorCountryId: result?.billCountryId,
    vendorCountryName: result?.billCountryName,
    deliveryAddressLine1: result?.deliveryAddressLine1,
    deliveryAddressLine2: result?.deliveryAddressLine2,
    deliveryCityName: result?.deliveryCityName,
    deliveryStateName: result?.deliveryStateName,
    deliveryCountryName: result?.deliveryCountryName,
    dueDate: result?.dueDate,
    itemList: result?.itemList,
    paymentList: result?.paymentList,
    otherCharges: result?.otherCharges,
  }

  return (
    <div id='kt_app_content' className='app-content  flex-column-fluid '>
      <div id='kt_app_content_container' className='app-container  container-xxl '>
        <div className='d-flex flex-column gap-5 gap-lg-5'>
          <KTCard>
            <KTCardBody>
              <div className='d-flex flex-wrap flex-stack gap-5 gap-lg-10'>
                <ul
                  className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-lg-n2 me-auto'
                  role='tablist'
                >
                  <li className='nav-item' role='presentation'>
                    <div
                      className='nav-link text-active-primary pb-4 active cursor-pointer'
                      data-bs-toggle='tab'
                      onClick={() => setActiveTab('bill_summary')}
                      aria-selected='true'
                      role='tab'
                    >
                      Bill Summary
                    </div>
                  </li>

                  <li className='nav-item' role='presentation'>
                    <div
                      className='nav-link text-active-primary pb-4 cursor-pointer'
                      data-bs-toggle='tab'
                      onClick={() => setActiveTab('payment_details')}
                      aria-selected='false'
                      role='tab'
                      tabIndex={-1}
                    >
                      Payment Details
                    </div>
                  </li>
                </ul>
                <Link
                  to={`/purchase/bills/list`}
                  className='btn btn-light btn-active-secondary btn-sm ms-auto me-lg-n7'
                >
                  <i className='ki-duotone ki-left fs-2' /> Back
                </Link>
              </div>
            </KTCardBody>
          </KTCard>

          <div className='d-flex flex-column flex-xl-row gap-5 gap-lg-5'>
            <div className='card flex-row-fluid'>
              <div className='card-header'>
                <div className='card-title'>
                  <h3>Bill Details (#{initialValues.billNumber})</h3>
                </div>
              </div>

              <div className='card-body'>
                <div className='table-responsive'>
                  <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
                    <tbody className='fw-semibold text-gray-600'>
                      <tr>
                        <td className='text-muted'>
                          <div className='d-flex align-items-center'>
                            <i className='ki-duotone ki-calendar fs-2 me-2'>
                              <span className='path1' />
                              <span className='path2' />
                            </i>{' '}
                            Bill Date
                          </div>
                        </td>
                        <td className='fw-bold text-end'>
                          {formatDate(initialValues.billDate, 'dd MMM yyyy')}
                        </td>
                      </tr>
                      <tr>
                        <td className='text-muted'>
                          <div className='d-flex align-items-center'>
                            <i className='ki-duotone ki-wallet fs-2 me-2'>
                              <span className='path1' />
                              <span className='path2' />
                              <span className='path3' />
                              <span className='path4' />
                            </i>{' '}
                            Bill Status
                          </div>
                        </td>
                        <td className='fw-bold text-end'>{initialValues.billStatusName}</td>
                      </tr>
                      <tr>
                        <td className='text-muted'>
                          <div className='d-flex align-items-center'>
                            <i className='ki-duotone ki-wallet fs-2 me-2'>
                              <span className='path1' />
                              <span className='path2' />
                              <span className='path3' />
                              <span className='path4' />
                            </i>{' '}
                            Payment Status
                          </div>
                        </td>
                        <td className='fw-bold text-end'>{initialValues.paymentStatusName}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className='card flex-row-fluid'>
              <div className='card-header'>
                <div className='card-title'>
                  <h3>Vendor Details</h3>
                </div>
              </div>

              <div className='card-body'>
                <div className='table-responsive'>
                  <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
                    <tbody className='fw-semibold text-gray-600'>
                      <tr>
                        <td className='text-muted'>
                          <div className='d-flex align-items-center'>
                            <i className='ki-duotone ki-profile-circle fs-2 me-2'>
                              <span className='path1' />
                              <span className='path2' />
                              <span className='path3' />
                            </i>{' '}
                            Vendor
                          </div>
                        </td>
                        <td className='fw-bold text-end'>
                          <div className='d-flex align-items-center justify-content-end'>
                            <label className='text-gray-600 text-hover-primary'>
                              {initialValues.vendorName || 'Name not available'}
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className='text-muted'>
                          <div className='d-flex align-items-center'>
                            <i className='ki-duotone ki-profile-circle fs-2 me-2'>
                              <span className='path1' />
                              <span className='path2' />
                              <span className='path3' />
                            </i>{' '}
                            Vendor Email
                          </div>
                        </td>
                        <td className='fw-bold text-end'>
                          <div className='d-flex align-items-center justify-content-end'>
                            <label className='text-gray-600 text-hover-primary'>
                              {initialValues.vendorEmail || 'Email is not available'}
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className='text-muted'>
                          <div className='d-flex align-items-center'>
                            <i className='ki-duotone ki-profile-circle fs-2 me-2'>
                              <span className='path1' />
                              <span className='path2' />
                              <span className='path3' />
                            </i>{' '}
                            Vendor Phone
                          </div>
                        </td>
                        <td className='fw-bold text-end'>
                          <div className='d-flex align-items-center justify-content-end'>
                            <label className='text-gray-600 text-hover-primary'>
                              {initialValues.vendorMobileNumber || 'Phone number is not available'}
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className='tab-content'>
            <div
              className={`tab-pane fade ${activeTab == 'bill_summary' ? 'active show' : ''} `}
              id='kt_ecommerce_purchase_bill_summary'
              role='tab-panel'
            >
              <div className='d-flex flex-column gap-5 gap-lg-5'>
                <div className='d-flex flex-column flex-xl-row gap-5 gap-lg-5'>
                  <div className='card flex-row-fluid position-relative' style={{width: '50%'}}>
                    <div className='position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5'>
                      <i className='ki-solid ki-two-credit-cart' style={{fontSize: '14em'}}></i>
                    </div>

                    <div className='card-header'>
                      <div className='card-title'>
                        <h3>Vendor Address</h3>
                      </div>
                    </div>

                    {initialValues.vendorAddressLine1 ||
                    initialValues.vendorAddressLine2 ||
                    initialValues.vendorStateName ||
                    initialValues.vendorCityName ? (
                      <div className='card-body'>
                        {initialValues.vendorAddressLine1}, {initialValues.vendorAddressLine2}
                        <br />
                        {initialValues.vendorStateName},
                        <br />
                        {initialValues.vendorCityName}.
                        <br />
                        {initialValues.vendorCountryName}
                        <br />
                        {/* {initialValues.billZipCode} */}
                      </div>
                    ) : (
                      <div className='card-body'>
                        <h4>No Vendor Address Available</h4>
                      </div>
                    )}
                  </div>

                  <div className='card flex-row-fluid position-relative' style={{width: '50%'}}>
                    <div className='position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5'>
                      <i className='ki-solid ki-delivery' style={{fontSize: '13em'}}></i>
                    </div>
                    <div className='card-header'>
                      <div className='card-title'>
                        <h3>Delivery Address</h3>
                      </div>
                    </div>
                    {initialValues.deliveryAddressLine1 ||
                    initialValues.deliveryAddressLine2 ||
                    initialValues.deliveryStateName ||
                    initialValues.deliveryCityName ? (
                      <div className='card-body'>
                        {initialValues.deliveryAddressLine1}
                        <br />
                        {initialValues.deliveryAddressLine2}
                        <br />
                        {initialValues.deliveryStateName},
                        <br />
                        {initialValues.deliveryCityName}.
                        <br />
                        {initialValues.deliveryCountryName}
                        <br />
                        {/* {initialValues.billZipCode} */}
                      </div>
                    ) : (
                      <div className='card-body'>
                        <h4>No Delivery Address Available</h4>
                      </div>
                    )}
                  </div>
                </div>

                <div className='card flex-row-fluid overflow-hidden'>
                  <div className='card-body'>
                    <div className='table-responsive'>
                      <table className='table align-middle table-row-dashed fs-6 gy-3 mb-0'>
                        <thead>
                          <tr className='text-start text-gray-600 '>
                            <th className='min-w-175px'>Product</th>
                            <th className='min-w-175px text-end'>Product Price</th>
                            <th className='min-w-70px text-end'>Quantity</th>
                            <th className='min-w-175px text-end'>Total Price</th>
                          </tr>
                        </thead>
                        <tbody className='fw-semibold text-gray-600'>
                          {initialValues?.itemList &&
                            initialValues?.itemList?.map((item: ReceivedBillItems) => {
                              return (
                                <tr key={item?.billItemId} className='hover:bg-gray-100'>
                                  <td>
                                    <div className='d-flex align-items-center'>
                                      <div className='ms-5'>
                                        <Link
                                          to={''}
                                          className='fw-bold text-gray-600 text-hover-primary'
                                        >
                                          {item.productName}
                                        </Link>
                                      </div>
                                    </div>
                                  </td>
                                  <td className='text-end'>{formatCurrency(item.productPrice)} </td>
                                  <td className='text-end'>{item.quantity} </td>
                                  <td className='text-end'>{formatCurrency(item?.subTotal)} </td>
                                </tr>
                              )
                            })}
                        </tbody>
                        <tfoot className='fw-semibold text-gray-600 mt-5'>
                          <tr>
                            <td className='text-end'></td>
                            <td className='text-end'></td>
                            <td className='text-end'></td>
                            <td className='text-end'>Other Charges</td>
                          </tr>
                          {initialValues.otherCharges?.map((oc) => (
                            <tr>
                              <td className='text-end'></td>
                              <td className='text-end'></td>
                              <td className='text-end'>{oc.label}:</td>
                              <td className='text-end'>{oc.value}</td>
                            </tr>
                          ))}
                          <tr>
                            <td className='text-end'></td>
                            <td className='text-end'></td>
                            <td className='text-end'>Total:</td>
                            <td className='text-end'>
                              {formatCurrency(initialValues?.orderTotal)}
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loading />}
    </div>
  )
}

export default BillDetails
