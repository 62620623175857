// @ts-nocheck
import {Column} from 'react-table'
import {RawMaterial} from '../core/_models'
import {StatusCell} from '../../../../../../_metronic/helpers/components/StatusCell'
import {ProductStockCustomHeader} from './ProductStockCustomHeader'
import {PaymentActionsCell} from './PaymentActionsCell'
import {Payment} from '../core/_model'
import {PaymentCustomHeader} from './PaymentCustomHeader'

const PaymentColumns: ReadonlyArray<Column<Payment>> = [
  {
    Header: (props) => (
      <PaymentCustomHeader tableProps={props} title='Order Number' className='min-w-125px' />
    ),
    id:'purchaseOrder.id',
    accessor: 'purchaseOrderNumber',
  },

  {
    Header: (props) => (
      <PaymentCustomHeader tableProps={props} title='vendor ' className='min-w-125px' />
    ),
    id:'purchaseOrder.vendor.firstName',
    accessor: 'vendorName',
  },

  {
    Header: (props) => (
      <PaymentCustomHeader tableProps={props} title='order Amount' className='min-w-125px' />
    ),
    id:'purchaseOrder.orderTotal',
    accessor: 'orderAmount',
    Cell: ({ value }) => formatCurrency(value),

  },

  {
    Header: (props) => (
      <PaymentCustomHeader tableProps={props} title='amount Paid' className='min-w-125px' />
    ),
    id:'amountPaid',
    accessor: 'amountPaid',
    Cell: ({ value }) => formatCurrency(value),

  },

  {
    Header: (props) => (
      <PaymentCustomHeader tableProps={props} title='payment Mode ' className='min-w-125px' />
    ),
    id:'paymentMode.id',
    accessor: 'paymentModeName',
  },

  // {
  //   Header: (props) => (
  //     <PaymentCustomHeader tableProps={props} title='Order Date' className='min-w-125px' />
  //   ),
  //   id:'purchaseOrder.orderDate',
  //   accessor: 'purchaseOrderDate',
  //   Cell: ({value}) => formatDate(value,'dd MMM yyyy'),
  // },

  {
    Header: (props) => (
      <PaymentCustomHeader tableProps={props} title='Payment Date' className='min-w-125px' />
    ),
    accessor: 'paymentDate',
   Cell: ({value}) => formatDate(value,'dd MMM yyyy'),
  },
  {
    Header: (props) => (
      <PaymentCustomHeader
        tableProps={props}
        title='Actions'
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <PaymentActionsCell id={props.data[props.row.index].id} />,
  },
]

export {PaymentColumns}
