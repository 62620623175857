import React from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const UnAuthorized = () => {

    return (
      <>
        {/* begin::Title */}
        <div className="card mb-5 mb-xl-5">
        <div className="card-body pt-9 pb-0">
        <h1 className='fw-bolder fs-2qx text-gray-900 mb-4 text-center'>Authorization Error</h1>
        {/* end::Title */}
  
        {/* begin::Text */}
        <div className='fw-semibold fs-6 text-gray-500 mb-7 text-center'>
          Something went wrong! You are not authorized to access this page..
        </div>
        {/* end::Text */}
  
        {/* begin::Illustration */}
        <div className=' text-center'>
          <img
            src={toAbsoluteUrl('/media/auth/authorization.jpg')}
            className='mw-100 mh-300px theme-light-show'
            alt=''
          />
          <img
            src={toAbsoluteUrl('/media/auth/authorization.jpg')}
            className='mw-100 mh-300px theme-dark-show'
            alt=''
          />
        </div>
        {/* end::Illustration */}
  
        {/* begin::Link */}
        <div className='mb-5 text-center'>
          <Link to='/dashboard' className='btn btn-sm btn-primary'>
            Return Home
          </Link>
        </div>
        {/* end::Link */}
        </div>
        </div>
      </>
    )
  
}

export default UnAuthorized