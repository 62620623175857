import { FC, useEffect, useState, } from 'react'
import * as Yup from 'yup'
import { Field, FormikProvider, useFormik } from 'formik'
import { Loading } from '../../../../../core/shared/components/Loading'
import { Result } from '../../../../../core/models/Result'
import clsx from 'clsx'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { camelize } from '../../../../../core/utils/StringHelpers'
import { AgentCommission, AgentPayout } from '../../core/_models'
import { createAgentPayout, getAgentCommisssionById } from '../../core/_requests'
import { getAccountList } from '../../../../settings/account/core/_requests'
import { KTCard, KTCardBody, PaginationFilter } from '../../../../../../_metronic/helpers'
import { SelectOptionProps } from '../../../../../core/models/SelectOptionProps'
import CustomSelect from '../../../../../core/shared/components/CustomSelect'
import { getPaymentModeList } from '../../../../settings/paymode/core/_requests'
import { PaymentMode } from '../../../../settings/paymode/core/_models'
import { format } from 'date-fns'
import { toZonedTime } from "date-fns-tz";
import Flatpickr from 'react-flatpickr';
import { formatCurrency } from '../../../../../core/utils/helpers'

interface Props {
  closeModal?: (close: boolean) => void
}

const paginationFilter: PaginationFilter = {
  pageNumber: 1,
  pageSize: 500,
}
const paymentSchema = Yup.object().shape({
  paymentDate: Yup.string().required("Please enter Payment date"),
  paymentModeId: Yup.number().required('Please select payment mode.'),
  referenceNumber: Yup.string().required("Please enter the reference Number"),
  notes: Yup.string(),
  bankName: Yup.string(),
  chequeNumber: Yup.number(),
  chequeDate: Yup.date(),
})

const PaymentForm: FC<Props> = ({ }) => {
  const [PaymentModeOptions, setPaymentModeOptions] = useState<SelectOptionProps[]>([])
  const [agentCommissionList, setAgentCommissionList] = useState<AgentCommission[]>([])
  const [selectedCommissionIds, setSelectedCommissionIds] = useState<number[]>([]);

  const { id } = useParams()
  const agentId = parseInt(id as string)
  const initialValues = {

    agentId: agentId,
    paymentDate: new Date(),
    paymentModeId: undefined,
    bankName: '',
    chequeNumber: 0,
    chequeDate: new Date(),
    referenceNumber: '',
    notes: '',
    agentCommissionIds: []
  }

  const handleCommissionCheckboxChange = (commissionId: number) => {
    setSelectedCommissionIds((prevSelected) =>
      prevSelected.includes(commissionId)
        ? prevSelected.filter(id => id !== commissionId)
        : [...prevSelected, commissionId]
    );
  };
  const calculateTotalCommission = () => {
    let total = 0;
    agentCommissionList.forEach((commission) => {
      if (selectedCommissionIds.includes(commission.id as number)) {
        total += commission.earnCommission || 0;
      }
    });
    return total;
  };

  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: paymentSchema,
    onSubmit: async (formValues, { setSubmitting, setFieldError }) => {
      debugger
      setSubmitting(true)
      try {
        let result: Result
        let payment: AgentPayout = {
          agentId: formValues.agentId as unknown as number,
          paymentDate: formValues.paymentDate,
          paymentModeId: formValues.paymentModeId,
          referenceNumber: formValues.referenceNumber,
          notes: formValues.notes,
          agentCommissionIds: selectedCommissionIds

        }

        result = await createAgentPayout(payment)
        if (result.hasOwnProperty('succeeded') && result?.succeeded) {
          setSubmitting(true)
          toast.success('Payment added successfully')
          navigate("/sales/agent/list")
          // closeModal(false)
        }
        else {
          if (result.propertyResults.length > 0) {
            result.propertyResults.map((error) =>
              setFieldError(camelize(error.propertyName), error.errorMessage)
            );
          }
          else {
            toast.error(result.exception)
          }
        }
      } catch (ex) {
        console.error(ex)
      }
    },
  })

  useEffect(() => {
    getPaymentModeList(paginationFilter).then((data) => {
      let result = data.data as PaymentMode[]
      let paymentModeArray: any[] = []
      result.map((item: any) => {
        return paymentModeArray.push({ value: item.id, label: item.name })
      })
      setPaymentModeOptions(paymentModeArray)
    })

    const fetchCommission = async () => {
      let result: any
      await getAgentCommisssionById(agentId).then((v) => {

        result = v as AgentCommission[]
        setAgentCommissionList(result)
      })
    }

    fetchCommission()

  }, [])


  return (
    <KTCard>
      <FormikProvider value={formik}>
        <form
          id='kt_modal_add_status_form'
          className='form'
          onSubmit={formik.handleSubmit}
          noValidate
        >
          <KTCardBody>
            <div
              className='d-flex flex-column'
              id='kt_modal_add_status_scroll'
            >
              <div className='row'>
                <div className="col-xl-6 col-lg-6 col-md-6 mb-5">
                  <label className="form-label required">Reference Number</label>
                  <Field
                    type='text'
                    className={clsx('form-control')}
                    {...formik.getFieldProps('referenceNumber')}
                    name='referenceNumber'
                    onChange={(e: any) => {
                      formik.handleChange(e)
                      // onQuantityChange( e.target.value)
                    }
                    }
                  />
                  {formik.touched.referenceNumber && formik.errors.referenceNumber && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.referenceNumber}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 mb-5">
                  <label className="form-label required">Payment Date</label>
                  <div>
                    <Flatpickr
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        { 'is-invalid': formik.touched.paymentDate && formik.errors.paymentDate },
                        { 'is-valid': formik.touched.paymentDate && !formik.errors.paymentDate }
                      )}
                      options={{
                        dateFormat: 'Y-m-d',
                        altFormat: 'd-m-Y'
                      }}
                      {...formik.getFieldProps('paymentDate')}
                      value={formik.values.paymentDate}
                      onChange={(date: Date[]) => {
                        debugger
                        const packDate = toZonedTime(date[0], 'Asia/Kolkata');
                        const convertedDate = format(packDate, 'yyyy-MM-dd')
                        console.log("selected date", convertedDate)
                        formik.setFieldValue('paymentDate', convertedDate)
                      }}
                    />

                    {formik.touched.paymentDate && formik.errors.paymentDate && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.paymentDate as string} </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>


              <div className="table-responsive">
                <table className="table border table-striped table-row-bordered gy-5 gs-7" id="kt_table_agentcommission">
                  <thead>
                    <tr className="fw-semibold fs-6 text-gray-800">
                      <th>Sale Order Number</th>
                      <th>Order Total</th>
                      <th>Commission Status</th>
                      <th>Earn Commission</th>
                    </tr>
                  </thead>
                  <tbody>
                    {agentCommissionList?.length > 0 ?
                      (agentCommissionList?.map((order) => (
                        <tr >
                          <td>
                            <a

                              className="text-gray-600 text-hover-primary mb-1"
                            >
                              {order.orderNumber}
                            </a>
                          </td><td>
                            {order.orderTotal ? formatCurrency(order.orderTotal) : '₹ 0.0'}
                          </td>
                          <td>{order.agentCommissionStatusName} </td>

                          <td>{order.earnCommission ? formatCurrency(order.earnCommission) : '₹ 0.0'} </td>
                          <td><input
                            type='checkbox'
                            checked={selectedCommissionIds.includes(order?.id as number)}
                            onChange={(e) => handleCommissionCheckboxChange(order?.id as number)}
                          />
                          </td>
                        </tr>
                      ))) :
                      <tr>
                        <td colSpan={4} className='text-center'>
                          No Record found
                        </td>
                      </tr>
                    }
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={5} className='text-end'>
                      Total Commission: {calculateTotalCommission()}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div className='fv-row mb-7'>
                <label className='fw-bold fs-6 mb-2 ms-1'>Notes</label>

                <textarea
                  rows={3}
                  placeholder='Notes'
                  {...formik.getFieldProps('notes')}

                  name='notes'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0 ps-4 ms-1',
                    { 'is-invalid': formik.touched.notes && formik.errors.notes },
                    {
                      'is-valid': formik.touched.notes && !formik.errors.notes,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                />
                {formik.touched.notes && formik.errors.notes && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.notes}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='row mb-5'>
                <label className="form-label required">Payment Mode</label>
                <div className='d-flex flex-wrap gap-3'>
                  {PaymentModeOptions.map((option) => (
                    <div key={option.value} className='form-check form-check-inline'>
                      <Field
                        type='radio'
                        className='form-check-input'
                        name='paymentModeId'
                        value={option.value}
                        checked={formik.values.paymentModeId === option.value}
                        onChange={() => formik.setFieldValue('paymentModeId', option.value)}
                      />
                      <label className='form-check-label'>{option.label}</label>
                    </div>
                  ))}
                </div>
              </div>
              {formik.values.paymentModeId === 2 && (
                <div className='row'>
                  <div className='col-xl-4 col-lg-4 col-md-6 mb-5'>
                    <label className='form-label required'>Bank Name</label>
                    <Field
                      type='text'
                      className={clsx('form-control')}
                      {...formik.getFieldProps('bankName')}
                      name='bankName'
                      onChange={(e: any) => {
                        formik.handleChange(e)
                        // onQuantityChange( e.target.value)
                      }
                      }
                    />
                  </div>

                  <div className='col-xl-4 col-lg-4 col-md-6 mb-5'>
                    <label className='form-label required'>Cheque Number</label>
                    <Field
                      type='text'
                      className={clsx('form-control')}
                      {...formik.getFieldProps('chequeNumber')}
                      name='chequeNumber'
                      onChange={(e: any) => {
                        formik.handleChange(e)
                        // onQuantityChange( e.target.value)
                      }
                      }
                    />
                  </div>

                  <div className='col-xl-4 col-lg-4 col-md-6 mb-5'>
                    <label className='form-label required'>Cheque Date</label>
                    <div>
                      <Flatpickr
                        className={clsx(
                          'form-control form-control-lg form-control-solid',

                        )}
                        options={{
                          dateFormat: 'Y-m-d',
                          altFormat: 'd-m-Y'
                        }}
                        {...formik.getFieldProps('chequeDate')}
                        value={formik.values.chequeDate}
                        onChange={(date: Date[]) => {
                          debugger
                          const packDate = toZonedTime(date[0], 'Asia/Kolkata');
                          const convertedDate = format(packDate, 'yyyy-MM-dd')
                          console.log("selected date", convertedDate)
                          formik.setFieldValue('chequeDate', convertedDate)
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}  
            </div>
          </KTCardBody>
          <div className="card-footer text-end">
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-status-modal-action='submit'
              disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
            >
              <span className='indicator-label'>Submit</span>
              {(formik.isSubmitting) && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
        {(formik.isSubmitting) && <Loading />}
      </FormikProvider >

    </KTCard>
  )
}

export { PaymentForm }
