// @ts-nocheck
import {Column} from 'react-table'
import {PaymentActionsCell} from './PaymentActionsCell'
import {Payment} from 'core/_model'
import {PaymentCustomHeader} from './PaymentCustomHeader'
import { SalesOrder } from '../../salesOrder/salesOrder-list/core/_models'
import { formatCurrency, formatDate } from '../../../../core/utils/helpers'

const saleorderTemplate = (value) => {
  return value ? value : '-';
};

const PaymentColumns: ReadonlyArray<Column<Payment>> = [
  {
    Header: (props) => (
      <PaymentCustomHeader tableProps={props} title='Payment Number' className='min-w-125px' />
    ),
    id:'paymentNumber',
    accessor: 'paymentNumber',
   
  },

  {
    Header: (props) => (
      <PaymentCustomHeader tableProps={props} title='customer ' className='min-w-125px' />
    ),
    id:'customer.printName',
    accessor: 'customerName',
  },

  {
    Header: (props) => (
      <PaymentCustomHeader tableProps={props} title='Amount Received' className='min-w-125px' />
    ),
    accessor: 'amountReceived',
    Cell: ({ value }) => formatCurrency(value),

  },
  {
    Header: (props) => (
      <PaymentCustomHeader tableProps={props} title='Ununsed Amount' className='min-w-125px' />
    ),
    accessor: 'advanceAmountLeft',
    Cell: ({ value }) => formatCurrency(value),

  },

  {
    Header: (props) => (
      <PaymentCustomHeader tableProps={props} title='payment Mode ' className='min-w-125px' />
    ),
    id:'paymentMode.id',
    accessor: 'paymentModeName',
  },

  {
    Header: (props) => (
      <PaymentCustomHeader tableProps={props} title='payment Date' className='min-w-125px' />
    ),
    id:'paymentDate',
    accessor: 'paymentDate',
    Cell: ({value}) => formatDate(value,'dd MMM yyyy'),
  },

  {
    Header: (props) => (
      <PaymentCustomHeader
        tableProps={props}
        title='Actions'
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <PaymentActionsCell id={props.data[props.row.index].id} />,
  },
]

export {PaymentColumns}
