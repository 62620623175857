import React, {useState} from 'react'
import {KTCard, KTCardBody, KTIcon} from '../../../../../_metronic/helpers'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {formatCurrency, formatDate} from '../../../../core/utils/helpers'
import {useQuery} from 'react-query'
import {getPaymentMadeList} from '../_request'
import {Loading} from '../../../../core/shared/components/Loading'
import {
  MustHavePermission,
  SWAction,
  SWResource,
  SWSection,
} from '../../../../core/shared/core/permissionUtils'
import {useNavigate} from 'react-router-dom'
import {PMActionsCell} from '../components/pmtable/PMActionsCell'
import PMAdvanceSearch from '../components/pmtable/PMAdvanceSearch'

const PaymentMadeList = () => {
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [filterQuery, setFilterQuery] = useState({})

  const handleFilterQueryChange = (newFilterQuery: any) => {
    setFilterQuery(newFilterQuery)
  }

  const {
    data: response,
    refetch,
    isLoading,
  } = useQuery(
    ['getPaymentMadeRecords', currentPage, pageSize, filterQuery],
    async () => {
      return await getPaymentMadeList({...filterQuery, pageNumber: currentPage, pageSize})
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const tableData = response?.data.sort((a: {id: number}, b: {id: number}) => b.id - a.id)

  return (
    <>
      <PMAdvanceSearch refetch={refetch} onFilterQueryChange={handleFilterQueryChange} />
      <KTCard>
        <div className='card-header justify-content-bewteen align-items-center'>
          <div className='card-title'>
            <h3>Payment Made</h3>
          </div>
          <div className='d-flex justify-content-end align-items-center gap-2'>
            {MustHavePermission(SWSection.Store, SWResource.PurchaseOrders, SWAction.Create) ? (
              <button
                type='button'
                className='btn btn-primary'
                onClick={() => navigate('/purchase/payment-made/add')}
              >
                <KTIcon iconName='plus' className='fs-2' />
                Add
              </button>
            ) : null}
          </div>
        </div>
        <KTCardBody className='py-4'>
          <div className='card'>
            <DataTable
              value={tableData}
              stripedRows
              tableStyle={{minWidth: '50rem'}}
              loading={isLoading}
            >
              <Column field={'paymentNumber'} header={'Payment Number'} sortable={true} />
              <Column field={'vendorName'} header={'Vendor Name'} sortable={true} />
              <Column
                field={'paymentDate'}
                header={'Payment Date'}
                body={(rowData) => formatDate(rowData?.paymentDate, 'dd MMM yyyy')}
                sortable={true}
              />
              <Column field={'paymentModeName'} header={'Payment Mode'} sortable={true} />
              <Column
                field={'amountPaid'}
                header={'Amount Paid'}
                body={(rowData) => formatCurrency(rowData?.amountPaid)}
                sortable={true}
              />
              <Column
                field={'actions'}
                header={'Actions'}
                body={(rowData) => (
                  <PMActionsCell id={rowData.id} status={rowData.paymentStatusId} />
                )}
                sortable={true}
              />
            </DataTable>

            <div className='d-flex justify-content-between align-items-center flex-wrap px-4 py-3 mx-auto gap-6'>
              <div className='d-flex align-items-center'>
                <button
                  className='btn btn-light btn-sm me-2'
                  onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                  disabled={currentPage === 1}
                >
                  <i className='bi bi-chevron-double-left'></i>
                </button>
                {Array.from({length: response?.pagination?.totalPages || 0}, (_, i) => i + 1).map(
                  (pageNum) => (
                    <button
                      key={pageNum}
                      className={`btn btn-sm mx-1 ${
                        pageNum === currentPage ? 'btn-primary' : 'btn-light'
                      }`}
                      onClick={() => setCurrentPage(pageNum)}
                    >
                      {pageNum}
                    </button>
                  )
                )}
                <button
                  className='btn btn-light btn-sm ms-2'
                  onClick={() =>
                    setCurrentPage((prev) => Math.min(prev + 1, response?.pagination?.totalPages))
                  }
                  disabled={currentPage === response?.pagination?.totalPages}
                >
                  <i className='bi bi-chevron-double-right'></i>
                </button>
              </div>
              <div className='d-flex align-items-center me-3'>
                <select
                  className='form-select form-select-sm'
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value))
                    setCurrentPage(1)
                  }}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
          </div>
          {isLoading && <Loading />}
        </KTCardBody>
      </KTCard>
    </>
  )
}

export default PaymentMadeList
