import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {KTCard, KTCardBody} from '../../../../../_metronic/helpers'
import {useState} from 'react'
import {useQuery} from 'react-query'
import {getBillsList} from '../_requests'
import {Loading} from '../../../../core/shared/components/Loading'
import {formatCurrency, formatDate} from '../../../../core/utils/helpers'
import BillAdvancedSearch from '../components/BillAdvanceSearch'
import {BillListListToolbar} from '../components/BillListToolbar'
import {BillsActionsCell} from '../components/bill-table/BillsActionsCell'
// import BillAdvancedSearch from '../conponents/BillAdvanceSearch'

const BillsListTable = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [filterQuery, setFilterQuery] = useState({})
  const [sortField, setSortField] = useState()
  const [sortOrder, setSortOrder] = useState<1 | -1>(1)

  const handleFilterQueryChange = (newFilterQuery: any) => {
    setFilterQuery(newFilterQuery)
  }

  const {
    data: response,
    refetch,
    isLoading,
  } = useQuery(
    ['getBillRecords', currentPage, pageSize],
    async () => {
      return await getBillsList({
        ...filterQuery,
        pageNumber: currentPage,
        pageSize,
        // sort: sortField,
        // order: sortOrder === 1 ? 'asc' : 'desc',
        // orderBy: [`${sortField} ${sortOrder === 1 ? 'asc' : 'desc'}`],
      })
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const onSort = (event: any) => {
    // setSortOrder(event.sortOrder)
    // if (event.sortField === 'vendorName') {
    //   setSortField('vendor.printName')
    //   setSortOrder(event.sortOrder === 1 ? 1 : -1)
    // } else if (event.sortField === 'paymentStatusName') {
    //   setSortField('paymentStatusId')
    //   setSortOrder(event.sortOrder === 1 ? 1 : -1)
    // } else {
    //   setSortField(event.sortField)
    //   setSortOrder(event.sortOrder)
    // }
  }

  return (
    <>
      <BillAdvancedSearch refetch={refetch} onFilterQueryChange={handleFilterQueryChange} />
      <KTCard>
        <div className='card-header justify-content-bewteen align-items-center'>
          <div className='card-title'>
            <h3>Bills</h3>
          </div>
          <BillListListToolbar />
        </div>
        <KTCardBody className='py-4'>
          <div className='card'>
            <DataTable
              value={response?.data}
              stripedRows
              tableStyle={{minWidth: '50rem'}}
              loading={isLoading}
              sortField={sortField}
              sortOrder={sortOrder}
              onSort={onSort}
            >
              <Column field={'billNumber'} header={'Bill Number'} sortable={true} />
              <Column field={'vendorName'} header={'Vendor Name'} sortable={true} />
              <Column
                field={'billDate'}
                header={'Bill Date'}
                body={(rowData) => formatDate(rowData?.billDate, 'dd MMM yyyy')}
                sortable={true}
              />
              <Column field={'paymentStatusName'} header={'Payment Status'} sortable={true} />
              <Column
                field={'orderTotal'}
                header={'Order Total'}
                body={(rowData) => formatCurrency(rowData?.orderTotal)}
                sortable={true}
              />
              <Column
                field={'actions'}
                header={'Actions'}
                body={(rowData) => (
                  <BillsActionsCell id={rowData.id} paymentStatusId={rowData.paymentStatusId} />
                )}
                sortable={true}
              />
            </DataTable>

            <div className='d-flex justify-content-between align-items-center flex-wrap px-4 py-3 mx-auto gap-6'>
              <div className='d-flex align-items-center'>
                <button
                  className='btn btn-light btn-sm me-2'
                  onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                  disabled={currentPage === 1}
                >
                  <i className='bi bi-chevron-double-left'></i>
                </button>
                {Array.from({length: response?.pagination?.totalPages || 0}, (_, i) => i + 1).map(
                  (pageNum) => (
                    <button
                      key={pageNum}
                      className={`btn btn-sm mx-1 ${
                        pageNum === currentPage ? 'btn-primary' : 'btn-light'
                      }`}
                      onClick={() => setCurrentPage(pageNum)}
                    >
                      {pageNum}
                    </button>
                  )
                )}
                <button
                  className='btn btn-light btn-sm ms-2'
                  onClick={() =>
                    setCurrentPage((prev) => Math.min(prev + 1, response?.pagination?.totalPages))
                  }
                  disabled={currentPage === response?.pagination?.totalPages}
                >
                  <i className='bi bi-chevron-double-right'></i>
                </button>
              </div>
              <div className='d-flex align-items-center me-3'>
                <select
                  className='form-select form-select-sm'
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value))
                    setCurrentPage(1)
                  }}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
          </div>
          {isLoading && <Loading />}
        </KTCardBody>
      </KTCard>
    </>
  )
}

export {BillsListTable}
