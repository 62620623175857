import {Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {
  MustHavePermission,
  SWAction,
  SWResource,
  SWSection,
} from '../../../core/shared/core/permissionUtils'
import {BillsListTable} from './pages/BillsListTable'
import UnAuthorized from '../../errors/components/UnAuthorized'
import BillsAddPage from './pages/BillsAddPage'
import BillDetails from './pages/BillDetails'
import BillEditPage from './pages/BillEditPage'

const Breadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/bills',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const BillsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          index
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Bill Records</PageTitle>
              {MustHavePermission(SWSection.Store, SWResource.PurchaseOrders, SWAction.Search) ? (
                <BillsListTable />
              ) : (
                <UnAuthorized />
              )}
            </>
          }
        />

        {/* to navigate to add page */}
        <Route
          path='add'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Add Bill Record</PageTitle>
              {MustHavePermission(SWSection.Store, SWResource.PurchaseOrders, SWAction.Create) ? (
                <BillsAddPage />
              ) : (
                <UnAuthorized />
              )}
            </>
          }
        />
        <Route
          path='detail/:id'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Detail Bill Record</PageTitle>
              {MustHavePermission(SWSection.Store, SWResource.PurchaseOrders, SWAction.View) ? (
                <BillDetails />
              ) : (
                <UnAuthorized />
              )}
            </>
          }
        />
        <Route
          path='edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Edit Bill Record</PageTitle>
              {MustHavePermission(SWSection.Store, SWResource.PurchaseOrders, SWAction.View) ? (
                <BillEditPage />
              ) : (
                <UnAuthorized />
              )}
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default BillsPage
